import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IStellenangebot } from 'src/app/interfaces/stellenangebot';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class StellenangeboteService {
    constructor(private httpClient: HttpClient) { }

    // Definieren des API-Keys
    options = {
        headers: new HttpHeaders()
            .set('x-api-key', environment.apiKey)
    };

    getStellenangebot(stellenangebotId: string): Observable<IStellenangebot> {

        // Abrufen eines Stellenangebots
        return this.httpClient.get<IStellenangebot>(environment.apiUrls.stellenangebote + stellenangebotId, this.options);
    }

    getStellenangebote(): Observable<IStellenangebot[]> {
        // Abrufen der Stellenangebote
        let stellenangebote = this.httpClient.get<IStellenangebot[]>(environment.apiUrls.stellenangebote, this.options);

        // Sortieren der Stellenangebote (Priorität, Titel)
        return stellenangebote.pipe(map(stellenangebot => stellenangebot.sort((a, b) => {
            if (a.Prioritaet === b.Prioritaet)
                return (a.Titel > b.Titel) ? 1 : -1;
            else
                return (a.Prioritaet) ? -1 : 1;
        })));
    }
}
