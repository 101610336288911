import { Injectable } from '@angular/core';
import { IStellenangebot } from 'src/app/interfaces/stellenangebot';

@Injectable({
    providedIn: 'root'
})
export class JobkategorieService {

    constructor() { }

    getJobkategorien(stellenangebote: IStellenangebot[]): string[] {
        let jobkategorien: string[] = [];

        stellenangebote.forEach(stellenangebot => {
            if (stellenangebot.JobKategorie && stellenangebot.JobKategorie.length > 0 && !jobkategorien.includes(stellenangebot.JobKategorie))
                jobkategorien.push(stellenangebot.JobKategorie);
        });
        jobkategorien.sort();

        return jobkategorien;
    }
}
