import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { IStellenangebot } from 'src/app/interfaces/stellenangebot';
import { StellenangeboteService } from 'src/app/services/stellenangebote/stellenangebote.service';
import { DOCUMENT } from '@angular/common';
import { MetaTagsService } from '../../services/metaTags/meta-tags.service';

@Component({
    selector: 'app-stellenangebot',
    templateUrl: './stellenangebot.component.html',
    styleUrls: ['./stellenangebot.component.css'],
})
export class StellenangebotComponent implements OnInit, OnDestroy {
    faAddressCard = faAddressCard;
    stellenangebotId: string = this.route.snapshot.paramMap.get(
        'stellenangebotId'
    );
    stellenangebot: IStellenangebot;
    headerImageName: string;
    googleJobsScriptName: string = 'GoogleJobsScript';
    public loading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private metaTagsService: MetaTagsService,
        private stellenangeboteService: StellenangeboteService,
        @Inject(DOCUMENT) private doc: Document
    ) {}

    ngOnInit(): void {
        this.stellenangeboteService
            .getStellenangebot(this.stellenangebotId)
            .subscribe((response: IStellenangebot) => {
                if (response) {
                    this.stellenangebot = response;
                    this.loading = false;

                    this.headerImageName = this.getHeaderImageName(
                        this.stellenangebot.JobKategorie
                    );

                    this.metaTagsService.setMetaTags({
                        title: `${this.stellenangebot.Titel} | ${this.stellenangebot.Standort.Ort} | ${this.stellenangebot.Firma}`,
                        description: `Bewerben Sie sich jetzt als "${this.stellenangebot.Titel}" bei ${this.stellenangebot.Firma} in ${this.stellenangebot.Standort.Ort}`,
                        imageName: this.headerImageName,
                    });

                    this.loadScriptGoogleJobs();
                } else {
                    this.router.navigate(['/404']);
                }
            });
    }

    ngOnDestroy(): void {
        this.removeScriptGoogleJobs();
    }

    public loadScriptGoogleJobs(): void {
        this.removeScriptGoogleJobs(); // Workaround for Google Jobs Crawler (otherwise existing twice)
        const script = this.doc.createElement('script');
        script.type = 'application/ld+json';
        script.id = this.googleJobsScriptName;
        script.innerHTML =
            `{
            "@context" : "https://schema.org/",
            "@type" : "JobPosting",
            "title" : "` +
            this.stellenangebot.Titel +
            `",
            "description" : "` +
            this.stellenangebot.Aufgaben_Titel +
            ` ` +
            this.stellenangebot.Aufgaben_Detail.replace(/[\n\r]/g, '') +
            `",
            "identifier": {
              "@type": "PropertyValue",
              "name": "` +
            this.stellenangebot.Firma +
            `"
            },
            "datePosted" : "` +
            this.stellenangebot.Created_At +
            `",` +
            (this.stellenangebot.Valid_Through
                ? `
            "validThrough" : "` +
                  this.stellenangebot.Valid_Through +
                  `",`
                : ``) +
            `
            "employmentType" : "` +
            (this.stellenangebot.JobTyp == 'Teilzeit'
                ? 'PART_TIME'
                : 'FULL_TIME') +
            `",
            "hiringOrganization" : {
              "@type" : "Organization",
              "name" : "` +
            this.stellenangebot.Firma +
            `",
              "sameAs" : "https://www.gloeckle-bau.de",
              "logo" : "` +
            environment.websiteUrl +
            `assets/images/logo-112x112.png"
            },
            "jobLocation": {
            "@type": "Place",
              "address": {
              "@type": "PostalAddress",
              "streetAddress": "` +
            this.stellenangebot.Standort.Strasse +
            ` ` +
            this.stellenangebot.Standort.Hausnummer +
            `",
              "addressLocality": "` +
            this.stellenangebot.Standort.Ort +
            `",
              "postalCode": "` +
            this.stellenangebot.Standort.PLZ +
            `",
              "addressCountry": "DE"
              }
            }
          }`; // TODO Prüfen, ob das Logo in Google Jobs angezeigt wird

        this.doc.head.appendChild(script);
    }

    public removeScriptGoogleJobs(): void {
        var googleJobsScript = this.doc.getElementById(
            this.googleJobsScriptName
        );
        if (googleJobsScript) {
            googleJobsScript.remove();
        }
    }

    public getHeaderImageName(jobKategorie): string {
        var name = 'Standard';
        if (
            ['Gewerblich', 'IT', 'Kaufmännisch', 'Technisch'].indexOf(
                jobKategorie
            ) >= 0
        ) {
            var min = 1;
            var max = 1;
            switch (jobKategorie) {
                case 'Gewerblich':
                    var max = 19;
                    break;
                case 'IT':
                    var max = 4;
                    break;
                case 'Kaufmännisch':
                    var max = 6;
                    break;
                case 'Technisch':
                    var max = 4;
                    break;
            }
            var randomNumber = Math.floor(Math.random() * (+max - +min) + +min);
            name = this.stringToImageName(jobKategorie) + '-' + randomNumber;
        }
        return 'header-' + name + '.jpg';
    }

    getUrl() {
        return (
            environment.websiteUrl +
            'stellenangebote/' +
            this.stellenangebot.ID +
            '/info/'
        ); // + encodeURIComponent(this.stellenangebot.Titel)
    }

    stringToImageName(str: string) {
        return str.replace(/[^a-zA-Z0-9\-_]/g, (s) => {
            var c = s.charCodeAt(0);
            if (c == 228) return 'ae';
            if (c == 196) return 'Ae';
            if (c == 246) return 'oe';
            if (c == 214) return 'Oe';
            if (c == 252) return 'ue';
            if (c == 220) return 'Ue';
            if (c == 223) return 'ss';
            if (c == 32) return '-';
            return '__' + ('000' + c.toString(16)).slice(-4);
        });
    }
}
