import { Component, OnInit } from '@angular/core';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-ausbildung',
    templateUrl: './ausbildung.component.html',
    styleUrls: ['./ausbildung.component.css']
})
export class AusbildungComponent implements OnInit {
    faAddressCard = faAddressCard;

    constructor() { }

    ngOnInit(): void {
    }

}
