import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IStellenangebot } from 'src/app/interfaces/stellenangebot';
import { StellenangeboteService } from 'src/app/services/stellenangebote/stellenangebote.service';
import { JoblevelService } from 'src/app/services/joblevel/joblevel.service';
import { JobkategorieService } from 'src/app/services/jobkategorie/jobkategorie.service';
import { JobstandortService } from 'src/app/services/jobstandort/jobstandort.service';
import { JobfilterService } from 'src/app/services/jobfilter/jobfilter.service';
import { isPlatformBrowser } from '@angular/common';
import {MetaTagsService} from '../../services/metaTags/meta-tags.service';

@Component({
    selector: 'app-stellenangebote',
    templateUrl: './stellenangebote.component.html',
    styleUrls: ['./stellenangebote.component.css']
})
export class StellenangeboteComponent implements OnInit {
    stellenangebote: IStellenangebot[] = [];
    jobkategorien: string[];
    jobkategorieSelected: string;
    joblevel: string[];
    joblevelSelected: string;
    jobstandorte: string[];
    jobstandortSelected: string;
    faTimesCircle = faTimesCircle;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        private title: Title,
        private stellenangeboteService: StellenangeboteService,
        private jobkategorieService: JobkategorieService,
        private joblevelService: JoblevelService,
        private jobstandortService: JobstandortService,
        public jobfilterService: JobfilterService,
        private metaTagsService: MetaTagsService,
        @Inject(PLATFORM_ID) private platformId: object
    ) { }

    ngOnInit(): void {
        this.metaTagsService.setMetaTags({
            title: 'Stellenangebote | Glöckle Bewerberportal',
            description: `Bei unseren Stellenangeboten legen wir besonderen Wert auf die individuelle Stärke des Einzelnen. Bewerben Sie sich jetzt bei Glöckle!`,
            imageName: 'stellenangebote.jpg'
        });

        // Abonnieren der Jobfilter
        this.jobfilterService.jobkategorie$
            .subscribe(jobkategorie => {
                this.jobkategorieSelected = jobkategorie;
            });
        this.jobfilterService.joblevel$
            .subscribe(joblevel => {
                this.joblevelSelected = joblevel;
            });
        this.jobfilterService.jobstandort$
            .subscribe(jobstandort => {
                this.jobstandortSelected = jobstandort;
            });

        // Übernehmen der Filter aus den Querystring-Parametern
        if (this.activatedRoute.snapshot.queryParams['kategorie'] || this.activatedRoute.snapshot.queryParams['level'] || this.activatedRoute.snapshot.queryParams['standort']) {
            if (this.activatedRoute.snapshot.queryParams['kategorie'])
                this.jobfilterService.setJobkategorie(this.activatedRoute.snapshot.queryParams['kategorie']);
            if (this.activatedRoute.snapshot.queryParams['level'])
                this.jobfilterService.setJoblevel(this.activatedRoute.snapshot.queryParams['level']);
            if (this.activatedRoute.snapshot.queryParams['standort'])
                this.jobfilterService.setJobstandort(this.activatedRoute.snapshot.queryParams['standort']);
            this.router.navigate(['/stellenangebote']);
        }

        this.stellenangeboteService.getStellenangebote()
            .subscribe((response: IStellenangebot[]) => {
                this.stellenangebote = response;

                this.jobkategorien = this.jobkategorieService.getJobkategorien(this.stellenangebote);
                this.joblevel = this.joblevelService.getJoblevel(this.stellenangebote);
                this.jobstandorte = this.jobstandortService.getJobstandorte(this.stellenangebote);

                // Übernehmen der Filter aus den Querystring-Parametern
                this.activatedRoute.queryParams.subscribe(params => {
                    if (this.jobkategorien.indexOf(params.kategorie) >= 0) {
                        this.jobkategorieSelected = params.kategorie;
                    }
                    if (this.joblevel.indexOf(params.level) >= 0) {
                        this.joblevelSelected = params.level;
                    }
                    if (this.jobstandorte.indexOf(params.standort) >= 0) {
                        this.jobstandortSelected = params.standort;
                    }
                });
            });
    }

    getUrlWithFilters() {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        var url = window.location.href.split('?')[0];
        if (this.jobkategorieSelected || this.joblevelSelected || this.jobstandortSelected)
            url += '?';
        if (this.jobkategorieSelected)
            url += '&kategorie=' + this.jobkategorieSelected;
        if (this.joblevelSelected)
            url += '&level=' + this.joblevelSelected;
        if (this.jobstandortSelected)
            url += '&standort=' + this.jobstandortSelected;
        return url.replace("?&", "?");
    }

    removeJobkategorie() {
        this.jobfilterService.setJobkategorie(null);
    }

    removeJoblevel() {
        this.jobfilterService.setJoblevel(null);
    }

    removeJobstandort() {
        this.jobfilterService.setJobstandort(null);
    }

    setJobkategorie(jobkategorie: string) {
        this.jobfilterService.setJobkategorie(jobkategorie);
    }

    setJoblevel(joblevel: string) {
        this.jobfilterService.setJoblevel(joblevel);
    }

    setJobstandort(jobstandort: string) {
        this.jobfilterService.setJobstandort(jobstandort);
    }
}
