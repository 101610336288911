import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IStellenangebot } from 'src/app/interfaces/stellenangebot';
import { MetaTagsService } from 'src/app/services/metaTags/meta-tags.service';
import { StellenangeboteService } from 'src/app/services/stellenangebote/stellenangebote.service';

@Component({
    selector: 'app-bewerben-ok',
    templateUrl: './bewerben-ok.component.html',
    styleUrls: ['./bewerben-ok.component.css']
})
export class BewerbenOkComponent implements OnInit, OnDestroy {
    stellenangebotId: string = this.route.snapshot.paramMap.get('stellenangebotId');
    stellenangebot: IStellenangebot;
    uuid: string = this.route.snapshot.paramMap.get('uuid');
    metaTagRobotsContent: string;

    constructor(private router: Router, private route: ActivatedRoute, private meta: Meta, private title: Title, private stellenangeboteService: StellenangeboteService, private metaTagsService: MetaTagsService) { }

    ngOnInit(): void {
        this.metaTagRobotsContent = this.metaTagsService.getMetaTagContent('robots');
        this.metaTagsService.updateMetaTagContent('robots', 'noindex');
        this.uuid = this.uuid.substr(0, 5) + '-' + this.uuid.substr(34); // Kürzen der UUID als vereinfachte Referenz für den Bewerber

        this.stellenangeboteService.getStellenangebot(this.stellenangebotId)
            .subscribe((response: IStellenangebot) => {
                if (response) {
                    this.stellenangebot = response;

                    this.title.setTitle('Glöckle Bewerberportal - Bewerbungseingang - ' + this.stellenangebot.Titel);
                    this.meta.updateTag({
                        name: 'description',
                        content: 'Glöckle Stellenangebot - Vielen Dank für Ihre Bewerbung als "' + this.stellenangebot.Titel + '"'
                    });
                } else {
                    this.router.navigate(['/404']);
                }
            });
    }

    ngOnDestroy(): void {
        this.metaTagsService.updateMetaTagContent('robots', 'all');
    }
}
