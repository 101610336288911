<main class="container-fluid | o-sections-space">
    <header class="c-page-header c-page-header--image-ueberuns | row">
        <div class="c-page-header__body">
            <h1>Ausbildung</h1>
        </div>
    </header>

    <section class="c-text-and-media">
        <header class="o-hgroup">
            <h2>
                Wir brennen f&uuml;r Dich - Deine Ausbildung beim Team
                Gl&ouml;ckle!
            </h2>
        </header>

        <div class="row mt-4">
            <div class="o-richtext | col-12 col-md-6">
                <p>
                    Die individuelle Stärke des Einzelnen und die Identifikation
                    mit „seinem“ Unternehmen und seinen Aufgaben sind Basis der
                    erfolgreichen Geschichte unseres Unternehmens. Die
                    Anerkennung, die wir als Ausbildungsbetrieb genießen und die
                    zahlreichen Auszeichnungen, die immer wieder unsere
                    Auszubildenden erhielten, ermöglichen uns, aus den eigenen
                    Reihen Führungskräfte nachzuziehen, die sich mit der
                    Tradition und dem Stil des Hauses identifizieren.
                </p>
                <p>
                    Eine Ausbildung bei Glöckle ist Dein Weg in eine gesicherte
                    Zukunft. Denn bei uns kannst Du Fähigkeiten und Berufe
                    erlernen, die immer gefragt sind. Die Baubranche boomt.
                    Daran siehst Du nicht nur, dass Du Dein Leben mit der
                    richtigen Ausbildung ohne Sorgen langfristig planen kannst,
                    sondern auch, dass wir als Unternehmen wollen, dass es Dir
                    bei Glöckle gefällt.
                </p>
                <p>
                    Die Verantwortung, Ausbildungsplätze bereitzustellen - heute
                    eine der dringendsten sozialen Aufgaben - werden wir auch in
                    Zukunft übernehmen, das steht fest!
                </p>
                <p>
                    Umfangreiche Informationen zu allen Ausbildungsstellen
                    erhältst Du auch in unserer
                    <a
                        href="https://www.gloeckle-bau.de/gloeckle-download/7198/"
                        >Azubi-Brosch&uuml;re als PDF</a
                    >.
                </p>
            </div>

            <div class="col-12 col-md-6 | mt-4 mt-md-0">
                <img
                    class="img-fluid"
                    src="/assets/media/wir-brennen-fur-dich.jpg"
                    alt="Mitarbeiter, die im virtuellen Feuer stehen"
                />
            </div>
        </div>
    </section>
    <section class="c-text-and-media">
        <header class="o-hgroup">
            <h2>Na? Wie war Deine erste Ausbildungswoche bei Gl&ouml;ckle?</h2>
        </header>

        <div class="row mt-4 flex-row-reverse">
            <div class="o-richtext | col-12 col-md-6">
                <p>
                    Ihr fragt Euch vielleicht, wie läuft es denn so in einer
                    Ausbildung? Was erwartet mich gleich in der ersten Woche?
                    Wie sind die Kollegen? Fragen über Fragen!
                </p>
                <p>
                    Um Dir einen ersten Eindruck zu vermitteln, wie es bei
                    Glöckle in der Ausbildung so zugehen kann, haben wir mit
                    unseren Nachwuchskräften einen kleinen Film für Euch
                    gedreht. Wir hoffen, dass er Euch gefällt!
                </p>
            </div>

            <div class="col-12 col-md-6 | mt-4 mt-md-0">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                        allowfullscreen
                        class="embed-responsive-item"
                        loading="lazy"
                        src="https://www.youtube-nocookie.com/embed/ofmztrvmH2g"
                    ></iframe>
                </div>
            </div>
        </div>
    </section>
    <section class="c-bodytext c-bodytext--default">
        <header class="o-hgroup">
            <h2>
                Damit es Dir bei uns so richtig gut geht, sorgen wir mit
                folgenden Benefits gut f&uuml;r Dich:
            </h2>
        </header>
        <div class="o-richtext">
            <ul>
                <li>Übertarifliche Ausbildungsvergütung</li>
                <li>Prämien für gute Leistungen</li>
                <li>
                    Freier Eintritt für Dich und Deine Freunde zum Glöckle See
                </li>
                <li>
                    Du bekommst Deinen eigenen Paten, der Dich durch Deine
                    Ausbildung begleitet.
                </li>
                <li>
                    Teamtrainings und Abenteuer im Rahmen der
                    Power-Azubi-Schmiede
                </li>
            </ul>
        </div>
    </section>
    <section class="c-cta-job-offers | o-section-shaded">
        <header class="o-hgroup">
            <p class="o-kicker">
                Du m&ouml;chtest gerne bei uns Deine Ausbildung starten?
            </p>
            <h2>Suche Dir den Ausbildungsberuf, der zu Dir passt!</h2>
        </header>
        <div class="o-richtext"></div>

        <a
            class="btn btn-primary | c-cta-job-offers__button"
            [routerLink]="['/stellenangebote']"
            [queryParams]="{ level: 'Ausbildung' }"
        >
            Zu den Stellenangeboten
        </a>
    </section>
</main>
