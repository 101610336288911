<main class="container-fluid">
    <header class="c-page-header c-page-header--image-stellenangebote | row">
        <div class="c-page-header__body">
            <h1>Stellenangebote</h1>
        </div>
    </header>

    <section class="mb-4">
        <div class="row">
            <mat-form-field class="col-12 col-sm-6 col-md-4 col-lg-3 order-2">
                <mat-label>Job-Kategorie</mat-label>
                <mat-select [(value)]="jobkategorieSelected" (selectionChange)="jobfilterService.setJobkategorie(jobkategorieSelected);">
                    <mat-option>alle</mat-option>
                    <mat-option *ngFor="let jobkategorie of jobkategorien" [value]="jobkategorie">
                        {{ jobkategorie }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-12 col-sm-6 col-md-4 col-lg-3 order-3">
                <mat-label>Job-Level</mat-label>
                <mat-select [(value)]="joblevelSelected" (selectionChange)="jobfilterService.setJoblevel(joblevelSelected);">
                    <mat-option>alle</mat-option>
                    <mat-option *ngFor="let joblevel of joblevel" [value]="joblevel">
                        {{ joblevel }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-12 col-sm-6 col-md-4 col-lg-3 order-4">
                <mat-label>Standort</mat-label>
                <mat-select [(value)]="jobstandortSelected" (selectionChange)="jobfilterService.setJobstandort(jobstandortSelected);">
                    <mat-option>alle</mat-option>
                    <mat-option *ngFor="let jobstandort of jobstandorte" [value]="jobstandort">
                        {{ jobstandort }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-copytoclipboard class="col-12 col-lg-3 order-1 order-lg-5" [clipboardText]="getUrlWithFilters()" tooltipText="Link dieser Seite mit aktueller Auswahl in die Zwischenablage kopieren" style="position: relative; top: 26px;"></app-copytoclipboard>
        </div>

        <div
            *ngIf="stellenangebote.length === 0"
            class="text-center mt-4 text-primary"
        >
            <div
                class="spinner-grow m-5"
                style="width: 3rem; height: 3rem;"
                role="status"
            >
            </div>
        </div>

        <div class="row | mt-4">
            <ng-container *ngFor="let stellenangebot of stellenangebote">
                <div
                    *ngIf="(stellenangebot.JobLevel == joblevelSelected || joblevelSelected == null) && (stellenangebot.JobKategorie == jobkategorieSelected || jobkategorieSelected == null) && (stellenangebot.Standort.Ort == jobstandortSelected || jobstandortSelected == null)"
                    class="job-offer-card | col-sm-12 col-md-4 col-lg-3"
                >
                    <div class="o-card o-card--small | h-100">
                        <div class="o-card__body | h-100 d-flex flex-column">
                            <h2 class="job-offer-card__headline | mb-4">
                                <a [routerLink]="['/stellenangebote/' + stellenangebot.ID + '/info/' + stellenangebot.Titel_Url]">
                                    {{ stellenangebot.Titel }}
                                </a>
                            </h2>
                            <mat-chip-list *ngIf="stellenangebot.JobKategorie || stellenangebot.JobLevel" aria-label="JobFilter">
                                <mat-chip *ngIf="stellenangebot.JobKategorie && stellenangebot.JobKategorie != jobkategorieSelected" (click)="setJobkategorie(stellenangebot.JobKategorie)" [matTooltip]="['nach Job-Kategorie &quot;' + stellenangebot.JobKategorie + '&quot; filtern']">{{ stellenangebot.JobKategorie }}</mat-chip>
                                <mat-chip *ngIf="stellenangebot.JobKategorie && stellenangebot.JobKategorie == jobkategorieSelected" (click)="removeJobkategorie()" class="aktiv" [matTooltip]="['Filter entfernen']">{{ stellenangebot.JobKategorie }}&nbsp;<fa-icon [fixedWidth]="true" [icon]="faTimesCircle"></fa-icon>
                                </mat-chip>
                                <mat-chip *ngIf="stellenangebot.JobLevel && stellenangebot.JobLevel != joblevelSelected" (click)="setJoblevel(stellenangebot.JobLevel)" [matTooltip]="['nach Job-Level &quot;' + stellenangebot.JobLevel + '&quot; filtern']">{{ stellenangebot.JobLevel }}</mat-chip>
                                <mat-chip *ngIf="stellenangebot.JobLevel && stellenangebot.JobLevel == joblevelSelected" (click)="removeJoblevel()" class="aktiv" [matTooltip]="['Filter entfernen']">{{ stellenangebot.JobLevel }}&nbsp;<fa-icon [fixedWidth]="true" [icon]="faTimesCircle"></fa-icon>
                                </mat-chip>
                                <mat-chip *ngIf="stellenangebot.Standort.Ort && stellenangebot.Standort.Ort != jobstandortSelected" (click)="setJobstandort(stellenangebot.Standort.Ort)" [matTooltip]="['nach Standort &quot;' + stellenangebot.Standort.Ort + '&quot; filtern']">{{ stellenangebot.Standort.Ort }}</mat-chip>
                                <mat-chip *ngIf="stellenangebot.Standort.Ort && stellenangebot.Standort.Ort == jobstandortSelected" (click)="removeJobstandort()" class="aktiv" [matTooltip]="['Filter entfernen']">{{ stellenangebot.Standort.Ort }}&nbsp;<fa-icon [fixedWidth]="true" [icon]="faTimesCircle"></fa-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </section>
</main>
