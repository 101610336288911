import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBewerbung } from 'src/app/interfaces/bewerbung';

@Injectable({
    providedIn: 'root'
})
export class BewerbungenService {
    constructor(private httpClient: HttpClient) { }

    // Definieren des API-Keys
    options = {
        headers: new HttpHeaders()
            .set('x-api-key', environment.apiKey)
    };

    saveBewerbung(bewerbung: IBewerbung): Observable<string> {
        return this.httpClient.post<string>(environment.apiUrls.bewerbung, JSON.stringify(bewerbung), this.options);
    }

    getBewerbungPresignedUrl(uuid: string, dateiName: string, dateiGroesse: number, googleRecaptchaToken: string): Observable<any> {
        let input = {
            "uuid": uuid,
            "dateiName": dateiName,
            "dateiGroesse": dateiGroesse,
            "googleRecaptchaToken": googleRecaptchaToken
        }
        return this.httpClient.post<any>(environment.apiUrls.bewerbungPresignedUrl, JSON.stringify(input), this.options);
    }

    uploadBewerbungsdatei(presignedUrl: string, datei): Observable<any> { // contentType
        //const headers = new HttpHeaders({ 'Content-Type': contenttype });
        const req = new HttpRequest(
            'PUT',
            presignedUrl,
            datei,
            {
                //headers: headers,
                reportProgress: true // Erkennen des aktuellen Upload-Zustands
            });
        return this.httpClient.request(req);
    }
}
