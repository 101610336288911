// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiKey: 'Ml93x8fiqqoQy0GSYsm35pUEa6oN04J9KE2MNyTj',
    apiUrls: {
        bewerbung: 'https://api.vin0030.dqnow.vintin-aws.de/bewerbung/',
        bewerbungPresignedUrl:
            'https://api.vin0030.dqnow.vintin-aws.de/bewerbungPresignedUrl/',
        stellenangebote:
            'https://api.vin0030.dqnow.vintin-aws.de/stellenangebote/',
    },
    externalUrls: {
        initiativeApplicationUrl:
            'https://karriere.gloeckle-bau.de/stellenangebote/66/info/initiativbewerbung',
    },
    googleRecaptchaSiteKey: '6Le1O_YUAAAAAKsUjD4c4FjvIqaIUfkBtufH7X-N',
    websiteUrl: 'https://vin0030.dqnow.vintin-aws.de/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
