<main class="container-fluid">
    <header class="c-page-header cpage-header--image-stellenangebote | row">
        <div class="c-page-header__body">
            <h1>Datenschutzerklärung der Unternehmensgruppe Glöckle</h1>
        </div>
    </header>
    <section class="row justify-content-center | my-5">
        <div class="col-12 col-md-8 ">
            <div class="o-richtext">
                <p>
                    Unternehmensgruppe Glöckle, bestehend aus:
                </p>
                <ul>
                    <li>Bauunternehmung Glöckle Holding GmbH</li>
                    <li>Glöckle GmbH</li>
                    <li>Bauunternehmung Glöckle Hoch- und Tiefbau GmbH</li>
                    <li>Bauunternehmung Glöckle Montagebau GmbH</li>
                    <li>Bauunternehmung Glöckle SF-Bau GmbH</li>
                    <li>Bauunternehmung Glöckle Baustoffwerke GmbH</li>
                </ul><br />

                <h2>I. Präambel</h2>
                <p>
                    Wir freuen uns über Ihren Besuch auf dem Karriereportal unserer Unternehmensgruppe. Informationsschutz und im speziellen Datenschutz hat, vor allem hier, einen hohen Stellenwert für die Geschäftsführung. Sollten Sie uns gegenüber Daten, im Zusammenhang mit den nachfolgend beschriebenen Verarbeitungen offenlegen, behandeln wir Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften der europäischen Union und der Bundesrepublik Deutschland, sowie dieser Datenschutzerklärung.
                </p>
                <p>
                    Die einzelnen Firmierungen der Glöckle-Gruppe haben als für die Verarbeitung Verantwortlicher zahlreiche technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Karriereseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es Ihnen als Betroffenem frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise per Post, an uns zu übermitteln.
                </p><br />

                <h2>II. Begriffsbestimmungen</h2>
                <p>
                    Die Datenschutzerklärung der Glöckle-Gruppe beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DSGVO) verwendet wurden. Die detaillierten Definitionen finden Sie im Art. 4 DSGVO. Im Wesentlichen sind dies folgende Begrifflichkeiten, hier vereinfacht beschrieben:
                </p><br />

                <h2>A. Personenbezogene Daten</h2>
                <p>
                    Dies sind alle Informationen die uns als Verantwortlichem zur Verfügung stehen um Sie als natürliche Person zu bestimmen. (z.B. Name, Adresse, E-Mail, Telefonnummer, IP-Adresse)
                </p><br />

                <h2>B. Betroffene Person</h2>
                <p>
                    Das sind Sie als natürliche Person, sofern wir Sie identifiziert haben.
                </p><br />

                <h2>C. Verarbeitung</h2>
                <p>
                    Eine Verarbeitung ist jedes Erfassen, Speichern, Weiterverarbeiten, Weiterleiten, Archivieren und Löschen von Daten. Hierbei ist es unerheblich ob der Vorgang mithilfe von IT-Systemen automatisiert vorgenommen wird oder ob er manuell (z.B. per Brief) erfolgt.
                </p><br />

                <h2>D. Profiling</h2>
                <p>
                    Profiling ist jede Art der automatisierten Verarbeitung von Ihren Daten, die darin besteht, dass diese Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf Sie beziehen, zu bewerten. Insbesondere um Aspekte bezüglich ihrer Arbeitsleistung, wirtschaftlichen Lage, Gesundheit, persönlicher Vorlieben, Interessen, Verhalten, Aufenthaltsort oder Ortswechsel zu analysieren oder vorherzusagen.
                </p><br />

                <h2>E. Verantwortlicher</h2>
                <p>
                    Verantwortlicher oder für die Verarbeitung Verantwortlich, ist die Firmierung der Glöckle-Gruppe mit der Sie eine Vertragsbeziehung haben. Dabei ist entscheidend das diese Firmierung selbständig über die Verarbeitungsverfahren und Mittel entscheiden kann.
                </p><br />

                <h2>F. Auftragsverarbeiter</h2>
                <p>
                    Ein Auftragsverarbeiter ist eine Firmierung die von einem Unternehmen der Glöckle-Gruppe damit beauftragt wurde ihr bei der Erhebung, Verarbeitung, Speicherung, Weiterleitung oder Löschung von Ihren Daten zu unterstützen. Meist sind dies IT-Dienstleister aber auch Entsorgungsunternehmen, die z.B. mit der Aktenvernichtung beauftragt sind.
                </p><br />

                <h2>G. Einwilligung</h2>
                <p>
                    Eine Einwilligung ist jede von Ihnen, für den bestimmten Einzelfall gegebene Willensbekundung. Dabei werden Sie vollumfänglich darüber informiert worin Sie einwilligen.
                </p><br />

                <h2>III. Verantwortlicher</h2>
                <p>
                    Verantwortlicher im Sinne der Datenschutz-Grundverordnung und anderer Bestimmungen mit datenschutzrechtlichem Charakter, für dieses Karriereportal der Glöckle Gruppe ist die:
                </p>
                <p>
                    Bauunternehmung Glöckle Holding GmbH<br />
                    Wirsingstraße 15<br />
                    D 97424 Schweinfurt
                </p>
                <p>
                    Geschäftsführung:<br />
                    Dipl.-Ing. Klaus Glöckle<br />
                    Dipl.-Ing. (FH) Carolin Glöckle<br />
                    Dipl.-Ing. (Univ.) Bernd Supthut<br />
                    Dipl.-Kfm. Armin Köchling
                </p>
                <p>
                    Telefon: 09721 / 8001-0<br />
                    E-Mail: <a href="mailto:info@gloeckle-bau.de" target="_blank">info@gloeckle-bau.de</a>
                </p><br />

                <h2>IV. Datenschutzbeauftragter</h2>
                <p>
                    Für die Firmierungen der Unternehmensgruppe Glöckle wurde ein Datenschutzbeauftragter bestellt. Dieser steht Ihnen jederzeit für Fragen im Zusammenhang mit Datenverarbeitungen zur Verfügung.
                </p>
                <p>
                    Datenschutzbeauftragter – Vertraulich –<br />
                    Wirsingstraße 15<br />
                    97424 Schweinfurt
                </p>
                <p>
                    Telefon: +49 (0) 9721 / 8001 – 950<br />
                    E-Mail: <a href="mailto:datenschutz@gloeckle-bau.de" target="_blank">datenschutz@gloeckle-bau.de</a>
                </p><br />

                <h2>V. Rechte des Betroffenen</h2>
                <p>
                    Gemäß dem Kapitel 3 der DSGVO haben Sie als Betroffener die hier folgend aufgeführten Rechte. Um diese, unsere Pflichten im Zusammenhang mit Ihren Rechten auch gesetzkonform zu erfüllen, richten Sie bitte entsprechende Anfragen an unseren Datenschutzbeauftragten.
                </p><br />

                <h2>A. Art. 15 Auskunftsrecht</h2>
                <p>
                    Sie haben ein unbeschränktes Recht, Auskunft über die von Ihnen verarbeiteten personenbezogenen Daten zu verlangen. Diese Auskunft muss Ihnen kostenfrei gewährt werden. Dabei können Sie Auskunft über folgende Informationen verlangen, die Ihnen auch in Kopie übermittelt werden müssen:
                </p>
                <ul>
                    <li>den Verarbeitungszweck ihrer Daten,</li>
                    <li>die Kategorien der Daten,</li>
                    <li>die internen und externen Empfänger Ihrer Daten,</li>
                    <li>die Dauer der Datenspeicherung,</li>
                    <li>ihre Rechte aus dem Kapitel 3, im Zusammenhang mit der Datenverarbeitung,</li>
                    <li>die Herkunft der Daten, sofern Sie nicht bei Ihnen erhoben wurden,</li>
                    <li>ob eine Profilbildung vorgenommen wurde,</li>
                    <li>ob Ihre Daten in ein Drittland (nicht EU und nicht EWR) weitergegeben wurden,</li>
                    <li>welche Datenschutzbehörde für unser jeweiliges Unternehmen zuständig ist,</li>
                </ul><br />

                <h2>B. Art. 16 Recht auf Berichtigung</h2>
                <p>
                    Sollten wir falsche Daten von Ihnen verarbeiten, können Sie diese jederzeit bei Ihrer Kontaktperson berichtigen lassen.
                </p><br />

                <h2>C. Art. 17 Recht auf Löschung</h2>
                <p>
                    Sie haben jederzeit das Recht dazu, die Löschung Ihrer personenbezogenen Daten zu verlangen. Es kann vorkommen, dass wir gesetzlich dazu verpflichtet sind Ihre Daten für eine gewisse Aufbewahrungsfrist vorzuhalten (z.B. 6 Jahre bei Geschäftspost oder 10 Jahre bei Unterlagen mit steuerrechtlichem Bezug), in einem solchen Fall werden wir Ihren Datensatz sperren, bis die Aufbewahrungsfrist abgelaufen ist und dann entsprechend den Datensatz löschen. Löschbegehren richten Sie bitte an den Datenschutzbeauftragten, dieser wird für Sie Ihre Rechte in unserer Unternehmensgruppe wahrnehmen.
                </p><br />

                <h2>D. Art. 18 Recht auf Einschränkung der Verarbeitung</h2>
                <p>
                    Wenn Sie die Richtigkeit unserer Daten zu Ihrer Person bestreiten, oder wenn Sie die Löschung Ihrer Daten ablehnen und stattdessen die Einschränkung verlangen (z.B. bei Werbezuschriften) dann können Sie die Einschränkung der Verarbeitung von uns verlangen. Wir werden Ihre Daten dann aufgesperrt setzen.
                </p><br />

                <h2>E. Art. 19 Mitteilungspflichten im Zusammenhang mit der Berichtigung, Löschung oder Einschränkung</h2>
                <p>
                    Wir sind dazu verpflichtet allen Empfängern Ihrer Daten, eine von Ihnen beauftragte Berichtigung, Löschung oder Einschränkung mitzuteilen, sofern dies möglich ist und mit einem verhältnismäßigen Aufwand zu realisieren ist. Wir werden Sie über die Empfänger Ihrer Daten informieren, wenn Sie dies verlangen.
                </p><br />

                <h2>F. Art. 20 Recht auf Datenübertragbarkeit</h2>
                <p>
                    Sie haben jederzeit das Recht bei unserem Unternehmen eine Übertragung Ihrer Daten an einen anderen Verantwortlichen zu verlangen. Dies bezieht sich auf alle Stammdaten, die wir über Sie führen. Sofern dies technisch möglich ist, werden wir den Datensatz in einem gängigen maschinenlesbaren Format (z.B. .csv) zur Verfügung stellen.
                </p><br />

                <h2>G. Art. 21 Widerspruchsrecht</h2>
                <p>
                    Sollte eine Datenverarbeitung auf den Art. 6 Abs. I lit. f (sogenanntes berechtigtes Interesse) begründet worden sein, können Sie gegen die Verarbeitung in diesem Zusammenhang Widerspruch einreichen.
                </p><br />

                <h2>H. Art. 77 Recht auf Beschwerde bei einer Aufsichtsbehörde</h2>
                <p>
                    Sie haben jederzeit das Recht sich bei der für unsere Unternehmensgruppe zuständigen Datenschutz Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass wir in irgendeiner Weise gegen die Bestimmungen der DSGVO verstoßen. Für alle Firmierungen unserer Unternehmensgruppe ist folgende Behörde zuständig:
                </p>
                <p>
                    Bayerisches Landesamt für Datenschutzaufsicht (BayLDA)<br />
                    Promenade 18<br />
                    91522 Ansbach</p>
                <p>
                    Auf das Internetangebot der Datenschutz Aufsichtsbehörde gelangen Sie über folgenden Link: <a href="https://www.lda.bayern.de/de/index.html" target="_blank">https://www.lda.bayern.de/de/index.html</a>
                </p><br />

                <h2>VI. Verarbeitungen</h2>
                <p>
                    In diesem Abschnitt werden wir die Datenverarbeitungen beschreiben, die im Zusammenhang mit unserem Karriereportal stehen.
                </p>
                <p>
                    Folgende Rechtsgrundlagen dienen uns für die Verarbeitung Ihrer Daten im Zuge des Bewerberverfahrens.
                </p>
                <p>
                    Art. 6 I lit. a DSGVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen. Dies trifft zu, wenn wir Sie darum ersuchen, ihre Bewerbungsunterlagen länger als gesetzlich gestattet aufzubewahren um eventuell später auf Sie zukommen zu können.
                </p>
                <p>
                    Ist die Verarbeitung Ihrer Daten zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, erforderlich, wie dies beim Bewerbungsverfahren der Fall ist, so beruht die Verarbeitung auf Art. 6 I lit. b DSGVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren Produkten oder Leistungen.
                </p>
                <p>
                    Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DSGVO.
                </p>
                <p>
                    Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DSGVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten von Ihnen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber besonders erwähnt wurden.
                </p><br />

                <h2>A. Karriereportal</h2>
                <p>
                    Wir betreiben dieses Karriereportal und erfassen in diesem Zusammenhang unterschiedliche Daten.
                </p><br />

                <h3>1. Cookies</h3>
                <p>
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
                </p>
                <p>
                    Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                </p>
                <p>
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                </p>
                <p>
                    Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Upload von Bewerbungsunterlagen) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Wir, als Webseitenbetreiber haben ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung unserer Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
                </p><br />

                <h3>2. Server-Log-Daten</h3>
                <p>
                    Die Firmierungen der Unternehmensgruppe Glöckle bzw. unser Karriereportalprovider (Amazon Web Services Inc.) erhebt Daten über Zugriffe auf unsere Seite und speichert diese als „Server-Logfiles“ ab. Folgende Daten werden so protokolliert:
                </p>
                <ul>
                    <li>Besuchte Website</li>
                    <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
                    <li>Menge der gesendeten Daten in Byte</li>
                    <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
                    <li>Verwendeter Browser</li>
                    <li>Verwendetes Betriebssystem</li>
                    <li>Verwendete IP-Adresse (anonymisiert)</li>
                </ul>
                <p>
                    Die erhobenen Daten dienen lediglich sicherheitstechnischen Auswertungen und zur Verbesserung der Website. Wir behalten uns allerdings vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
                </p><br />

                <h3>3. Drittmodule / Analysetools / Werbung</h3>
                <h4>IP Anonymisierung</h4>
                <p>
                    Wir haben auf dieser Karriereseite die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
                </p><br />

                <h4>Browser Plugin</h4>
                <p>
                    Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall ggf. nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
                </p><br />

                <h4>Auftragsdatenverarbeitung</h4>
                <p>
                    Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google vollständig um.
                </p><br />

                <h5>(1) Google Web Fonts</h5>
                <p>
                    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
                </p>
                <p>
                    Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                </p>
                <p>
                    Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
                </p>
                <p>
                    Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a>.
                </p><br />

                <h5>(2) Facebook-Link</h5>
                <p>
                    Wir haben auf dieser Internetseite eine Verlinkung des Unternehmens Facebook integriert. Facebook ermöglicht den Nutzern des sozialen Netzwerkes unter anderem die Erstellung von privaten Profilen, den Upload von Fotos und eine Vernetzung über Freundschaftsanfragen.
                </p>
                <p>
                    Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, USA. Für die Verarbeitung personenbezogener Daten Verantwortlicher ist, wenn eine betroffene Person außerhalb der USA oder Kanada lebt, die Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.
                </p>
                <p>
                    Eine Gesamtübersicht über alle Facebook-Plug-Ins kann unter <a href="https://developers.facebook.com/docs/plugins/?locale=de_DE" target="_blank">https://developers.facebook.com/docs/plugins/?locale=de_DE</a> abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält Facebook Kenntnis darüber, welche konkrete Unterseite unserer Webseite durch Sie besucht wird.
                </p>
                <p>
                    Sofern Sie gleichzeitig bei Facebook eingeloggt sind, erkennt Facebook mit jedem Aufruf unserer Internetseite durch Sie und während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite unserer Internetseite Sie besuchen. Diese Informationen werden durch die Facebook-Komponente gesammelt und durch Facebook ihrem jeweiligen Facebook-Account zugeordnet. Betätigt die betroffene Person einen der auf unserer Internetseite integrierten Facebook-Buttons, beispielsweise den „Gefällt mir“-Button, oder gibt die betroffene Person einen Kommentar ab, ordnet Facebook diese Information ihrem persönlichen Facebook-Benutzerkonto zu und speichert diese personenbezogenen Daten.
                </p>
                <p>
                    Facebook erhält über die Facebook-Komponente immer dann eine Information darüber, dass Sie unsere Internetseite besucht haben, wenn Sie zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Facebook eingeloggt sind; dies findet unabhängig davon statt, ob Sie die Facebook-Komponente anklicken oder nicht. Ist eine derartige Übermittlung dieser Informationen an Facebook von Ihnen nicht gewollt, können Sie die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus ihrem Facebook-Account ausloggen.
                </p>
                <p>
                    Die von Facebook veröffentlichte Datenrichtlinie, die unter <a href="https://de-de.facebook.com/about/privacy/" target="_blank">https://de-de.facebook.com/about/privacy/</a> abrufbar ist, gibt Aufschluss über die Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch Facebook. Ferner wird dort erläutert, welche Einstellungsmöglichkeiten Facebook zum Schutz der Privatsphäre der betroffenen Person bietet. Zudem sind unterschiedliche Applikationen erhältlich, die es ermöglichen, eine Datenübermittlung an Facebook zu unterdrücken. Solche Applikationen können durch Sie genutzt werden, um eine Datenübermittlung an Facebook zu unterdrücken.
                </p>
                <p>
                    Die Verwendung eines Facebook-Links erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien.
                </p><br />

                <h5>(3) Instagramm-Plugin</h5>
                <p>
                    Wir haben auf dieser Internetseite Komponenten des Dienstes Instagram integriert. Instagram ist ein Dienst, der als audiovisuelle Plattform zu qualifizieren ist und den Nutzern das Teilen von Fotos und Videos und zudem eine Weiterverbreitung solcher Daten in anderen sozialen Netzwerken ermöglicht.
                </p>
                <p>
                    Betreibergesellschaft der Dienste von Instagram ist die Instagram LLC, 1 Hacker Way, Building 14 First Floor, Menlo Park, CA, USA.
                </p>
                <p>
                    Durch jeden Aufruf einer der Einzelseiten dieser Webseiten, die durch die Unternehmensgruppe Glöckle betrieben wird und auf welcher eine Instagram-Komponente (Insta-Button) integriert wurde, wird der Internetbrowser auf Ihrem IT-System automatisch durch die jeweilige Instagram-Komponente veranlasst, eine Darstellung der entsprechenden Komponente von Instagram herunterzuladen. Im Rahmen dieses technischen Verfahrens erhält Instagram Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch Sie besucht wird.
                </p>
                <p>
                    Sofern Sie gleichzeitig bei Instagram eingeloggt sind, erkennt Instagram mit jedem Aufruf unserer Internetseite durch Sie und während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite Sie besuchen. Diese Informationen werden durch die Instagram-Komponente gesammelt und durch Instagram Ihrem jeweiligen Instagram-Account zugeordnet. Betätigen Sie einen der auf unserer Internetseite integrierten Instagram-Buttons, werden die damit übertragenen Daten und Informationen ihrem persönlichen Instagram-Benutzerkonto zugeordnet und von Instagram gespeichert und verarbeitet.
                </p>
                <p>
                    Instagram erhält über die Instagram-Komponente immer dann eine Information darüber, dass Sie unsere Internetseite besucht haben, wenn Sie zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Instagram eingeloggt sind; dies findet unabhängig davon statt, ob Sie die Instagram-Komponente anklicken oder nicht. Ist eine derartige Übermittlung dieser Informationen an Instagram von Ihnen nicht gewollt, können Sie die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus ihrem Instagram-Account ausloggen.
                </p>
                <p>
                    Weitere Informationen und die geltenden Datenschutzbestimmungen von Instagram können unter <a href="https://help.instagram.com/155833707900388" target="_blank">https://help.instagram.com/155833707900388</a> und <a href="https://www.instagram.com/about/legal/privacy/" target="_blank">https://www.instagram.com/about/legal/privacy/</a> abgerufen werden.
                </p>
                <p>
                    Die Verwendung des Instagram-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien.
                </p><br />

                <h2>B. Kontaktaufnahme / Anfragen</h2>
                <p>
                    Nachfolgend beschreiben wir die Möglichkeiten der Kontaktaufnahme mit den Firmierungen und Mitarbeitern unserer Unternehmensgruppe.
                </p><br />

                <h3>1. Kontaktformular</h3>
                <p>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>
                <p>
                    Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit im ersten Schritt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Im weiterführenden Schriftwechsel kann es zu einem Wechsel der Rechtmäßigkeit kommen (z.B. wenn Sie um ein Angebot bitten), dann werden Ihre Daten nach Art. 6 Abs. 1 lit b DSGVO verarbeitet.
                </p>
                <p>
                    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben hiervon unberührt.
                </p><br />

                <h3>2. E-Mail</h3>
                <p>
                    Wenn Sie uns per E-Mail Anfragen zukommen lassen, werden Ihre Angaben aus der E-Mail inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>
                <p>
                    Die Verarbeitung der in der Mail eingegebenen Daten erfolgt somit auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Im weiterführenden Schriftwechsel kann es zu einem Wechsel der Rechtmäßigkeit kommen (z.B. wenn es sich um einen Geschäftlichen Schriftwechsel handelt), dann werden Ihre Daten nach Art. 6 Abs. 1 lit b DSGVO verarbeitet.
                </p>
                <p>
                    Die von Ihnen in der Mail eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben hiervon unberührt.
                </p><br />

                <h3>C. Umgang mit Bewerbungsunterlagen</h3>
                <p>
                    Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Bewerbung ist §26 des Datenschutz-Anpassungs- und Umsetzungsgesetzes (DSAnpUG-EU) umgangssprachlich BDSG (neu).
                </p>
                <p>
                    Erhoben und verarbeitet werden hierfür Daten nur, soweit dies gesetzlich vorgeschrieben ist. Soweit eventuell weitere Daten nicht unmittelbar für die Begründung des Beschäftigungsverhältnisses erforderlich sind, stützt sich die Verarbeitung auf ein berechtigtes Interesse des Unternehmens gem. Art. 6 Abs. 1 lit. f DSGVO.
                </p>
                <p>
                    Ein berechtigtes Interesse kann sich z. B. aus internen Organisations- und Verwaltungszwecken, zum Schutz der Einrichtungen, Anlagen und Vermögenswerte des Unternehmens sowie der Datenverarbeitungsanlagen und Daten ergeben. Eine Verarbeitung Ihrer Daten ist hier zulässig, wenn nicht der Schutz Ihrer Interessen, Grundrechte und Grundfreiheiten überwiegt.
                </p>
                <p>
                    Im Einzelfall können wir auch Ihre Einwilligung in die Verarbeitung oder Übermittlung Ihrer Daten einholen. Ihre Einwilligung ist in diesen Fällen freiwillig und kann von Ihnen, soweit nichts Abweichendes vereinbart ist, für die Zukunft jederzeit widerrufen werden.
                </p>
                <p>
                    Eine Übermittlung der Bewerbungsunterlagen findet innerhalb der Firmengruppe statt. Im Bewerbungsprozess wird dadurch die jeweilige Fachabteilung eingebunden. Die Fachabteilungen sind angewiesen, Bewerbungsunterlagen streng vertraulich zu behandeln. Eine Weitergabe der Unterlagen in ein Drittland findet nicht statt.
                </p>
                <p>
                    Die zuständige und verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer persönlichen Daten ist Bauunternehmung Glöckle Holding GmbH in der Bundesrepublik Deutschland.
                </p>
                <p>
                    Ihre personenbezogenen Daten werden nur gespeichert, solange die Kenntnis der Daten für die o.g. Zwecke erforderlich sind oder gesetzliche oder vertragliche Aufbewahrungsvorschriften bestehen. Die Bewerbungsunterlagen werden in der Regel 6 Monate nach Abschluss des Bewerbungsverfahrens gelöscht.
                </p>
                <p>
                    Es besteht die Möglichkeit zur längeren Speicherung Ihrer Daten, wenn Sie uns Ihre Einwilligung mit folgendem Wortlaut zukommen lassen:
                </p>
                <p>
                    Ich willige ein, dass meine Bewerbungsunterlagen auf Grundlage der Punkte aus Überschrift “Umgang mit Bewerbungsunterlagen gemäß Art. 13 DSGVO” der Datenschutzerklärung Ihrer Internetseite für 12 Monate in den Bewerberpool aufgenommen werden. Während dieses Zeitraums kann die Bauunternehmung Glöckle Holding GmbH mich gerne zu neuen Job-Angeboten kontaktieren.
                </p>
                <p>
                    Auftragsverarbeiter im Zusammenhang mit dem Karriereportal
                    Unser Karriereportal wird auf den IT-Systemen der Amazon Web Services Inc. betrieben. Der Serverstandort ist hierbei Frankfurt am Main. Wir haben mit Amazon Web Services die notwendigen vertraglichen Rechtsgrundlagen für eine Auftragsverarbeitung geschlossen. Dies sind folgende:
                </p>
                <ul>
                    <li>Nutzungsvereinbarung der Amazon Web Services,</li>
                    <li>DPA (Data Processing Addendum) für die Auftragsverarbeitung inkl. der</li>
                    <li>EU-Standardvertragsklauseln um die Datenverarbeitungen durch ein Unternehmen aus einem Drittstaat zu begründen,</li>
                    <li>Abschließend ist Amazon Web Services Inc. ein Unternehmen, dass sich dem EU-US Privacy Shield unterworfen hat,</li>
                </ul>
                <p>
                    Im Rahmen des automatisierten Workflows werden ihre Bewerberdaten von den Amazon Web Services an unsere Unternehmensumgebung in der Microsoft Azure Cloud übergeben. Der Serverstandort unseres Unternehmens-Tenant ist ebenfalls auf die Rechenzentren Europa beschränkt. Dennoch haben wir auch mit Microsoft Azure entsprechende vertragliche Regelungen für die Datenübermittlung an die Microsoft Server geschlossen. Rechtsgrundlage hierbei sind folgende:
                </p>
                <ul>
                    <li>Nutzungsvereinbarungen der Microsoft Azure Umgebung,</li>
                    <li>DPA (Data Processing Addendum) für die Auftragsverarbeitung inkl. der</li>
                    <li>EU-Standardvertragsklauseln um die Datenverarbeitungen durch ein Unternehmen aus einem Drittstatt zu begründen,</li>
                    <li>Abschließend ist die Microsoft Corporation ein Unternehmen, dass sich dem EU-US Privacy Shield unterworfen hat.</li>
                </ul><br />

                <h2>VII. Schutz / Verschlüsselung</h2>
                <p>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                </p>
                <p>
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                </p><br />

                <h2>Google reCAPTCHA</h2>
                <p>
                    Auf unserem Karriereportal verwenden wir auch die reCAPTCHA Funktion von Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“). Diese Funktion dient vor allem zur Unterscheidung, ob eine Eingabe durch eine natürliche Person, also Sie, erfolgt oder missbräuchlich durch maschinelle und automatisierte Verarbeitung erfolgt (sprich einem Computer-Algorithmus).
                </p>
                <p>
                    Der Dienst umfasst den Versand folgender von Google für den Dienst reCAPTCHA benötigter Daten an Google und erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Feststellung der individuellen Eigenverantwortung im Internet und der Vermeidung von Missbrauch und Spam.
                </p>
                <p>
                    Verarbeitete Daten:
                </p>
                <ul>
                    <li>Referrer URL (die Adresse der Seite von der der Besucher kommt)</li>
                    <li>IP-Adresse (z.B. 255.124.123.1)</li>
                    <li>Infos über das Betriebssystem (die Software, die den Betrieb Ihres Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)</li>
                    <li>Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)</li>
                    <li>Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der Tastatur ausführen wird gespeichert)</li>
                    <li>Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf Ihrem PC voreingestellt haben wird gespeichert)</li>
                    <li>Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die Webseiten ermöglicht, sich an den User anzupassen. JavaScript-Objekte können alle möglichen Daten unter einem Namen sammeln)</li>
                    <li>Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung besteht)</li>
                </ul>
                <p>
                    Im Rahmen der Nutzung von Google reCAPTCHA kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen.
                </p>
                <p>
                    Für den Fall der Übermittlung von personenbezogenen Daten an die Google LLC. mit Sitz in den USA, hat sich Google LLC. für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden: <a href="https://www.privacyshield.gov/list" target="_blank">https://www.privacyshield.gov/list</a>
                </p>
                <p>
                    Weiterführende Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google können Sie einsehen unter: <a href="https://www.google.com/intl/de/policies/privacy/" target="_blank">https://www.google.com/intl/de/policies/privacy/</a>
                </p><br />

                <h2>VIII. Profiling</h2>
                <p>
                    Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung oder ein Profiling, besonders im Rahmen des Bewerbungsverfahrens.
                </p><br />

                <h2>IX. Aktualität / Stand</h2>
                <p>
                    Diese Datenschutzerklärung hat den Stand Juni 2020 und unterliegt der stetigen Fortschreibung und Anpassung an neue gesetzliche Rahmenbedingungen und technische Entwicklungen.
                </p>
            </div>
        </div>
    </section>
</main>
