<main class="container-fluid | o-sections-space">
    <header class="c-page-header c-page-header--image-ueberuns | row">
        <div class="c-page-header__body">
            <h1>Unternehmensgruppe GL&Ouml;CKLE</h1>
        </div>
    </header>

    <section class="row">
        <header class="col-12 | mb-4">
            <h2>Unternehmensgruppe Gl&ouml;ckle &ndash; WIR als Arbeitgeber</h2>
        </header>
        <div class="col-lg-7">
            <div class="o-richtext">
                <p>
                    Unsere Mitarbeiter und Mitarbeiterinnen sind unser höchstes
                    Gut: Sie sind das Fundament unserer Unternehmensgruppe und
                    der Schlüssel unseres Erfolges – seit über 110 Jahren!
                </p>
                <p>
                    Wenn auch Sie tatkräftig anpacken wollen, wenn auch Sie sich
                    für verantwortungsvolle Projekte begeistern können und wenn
                    auch Sie Neues entstehen lassen möchten, dann sind Sie bei
                    uns richtig! Viele engagierte und motivierte Kollegen und
                    Kolleginnen warten bereits auf "Sie"!
                </p>
                <p>
                    Wir bieten Ihnen sehr gute Einstiegsmöglichkeiten,
                    regelmäßige Weiterbildungen, übertarifliche Vergütungen und
                    attraktive Sozialleistungen sowie eine familiäre
                    Unternehmensstruktur – mit Kultur!
                </p>
                <p>
                    Bauen Sie Ihre Karriere; bauen Sie mit uns und bewerben Sie
                    sich jetzt!
                </p>
                <p>Mit Glöckle in die Zukunft!</p>
                <p>Das steht fest!</p>
            </div>

            <div class="c-intro-media__signature | mt-3">
                <svg
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    stroke-linejoin="round"
                    stroke-miterlimit="2"
                    viewBox="0 0 175 21"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill-rule="nonzero">
                        <path
                            d="m-.038.088c.003-.025.011-.042.026-.053s.035-.019.059-.022.05-.005.078-.005c.012-.026.024-.058.036-.096.012-.037.024-.078.035-.121.01-.043.021-.088.032-.133s.021-.088.03-.129.018-.079.027-.112c.008-.033.015-.059.022-.078-.035.001-.064.002-.088.003-.025.001-.048.003-.068.006-.021.003-.044.006-.069.011.005-.025.023-.045.055-.058s.082-.019.149-.019c.033 0 .067.001.102.004.034.003.065.006.094.008.029.003.051.006.066.009-.016.016-.033.026-.05.029-.018.004-.034.006-.047.006h-.058c-.019 0-.036 0-.051.001-.003.014-.007.036-.012.065-.005.03-.012.064-.019.102-.007.039-.015.078-.023.119-.008.042-.016.08-.023.117-.008.037-.015.069-.022.096-.011.043-.023.079-.036.108s-.029.05-.048.063c.035.002.072.005.111.011.039.004.075.007.106.008-.019.019-.039.031-.06.036s-.039.007-.053.007c-.027 0-.053 0-.078.001-.026 0-.052 0-.076.001-.025.001-.05.002-.074.005-.025.002-.049.005-.073.01z"
                            transform="matrix(24 0 0 24 1.19 18.162)"
                        />
                        <path
                            d="m.047.038c-.013 0-.023-.011-.03-.032s-.011-.045-.011-.072c0-.032.005-.07.016-.114s.025-.09.042-.139.037-.096.059-.143c.021-.047.043-.089.066-.127s.044-.068.064-.091.037-.034.052-.034c.012 0 .021.007.026.021.004.015.007.029.007.042 0 .019-.004.041-.012.068-.009.027-.02.055-.035.085-.015.031-.032.061-.051.09s-.04.056-.063.08c-.023.025-.046.044-.069.058-.008.019-.015.041-.02.065-.006.024-.01.047-.013.07s-.004.044-.004.062c0 .007 0 .014.001.019 0 .005 0 .01.001.015.009-.027.02-.054.031-.08.011-.025.022-.049.035-.071.013-.021.026-.04.041-.057.014-.016.029-.029.045-.038s.033-.013.051-.013c.019 0 .034.004.045.013s.018.021.024.034c.004.014.007.028.007.043 0 .021-.003.041-.01.06s-.015.038-.022.056-.011.035-.011.052c0 .013.003.023.009.029.005.006.012.009.021.009.015 0 .033-.007.052-.022.018-.015.037-.034.056-.059.019-.024.035-.05.048-.079l.013.012c-.014.033-.031.062-.051.088-.02.027-.042.048-.066.063s-.049.023-.075.023c-.023 0-.04-.006-.051-.018-.012-.012-.018-.027-.018-.045 0-.014.003-.03.008-.047s.011-.035.016-.053.007-.035.007-.051c0-.017-.004-.03-.011-.037s-.016-.011-.027-.011c-.017 0-.032.007-.045.02s-.025.03-.036.051-.022.044-.031.067c-.01.023-.019.045-.028.066s-.017.039-.026.052-.018.02-.027.02zm.074-.347c.019-.015.037-.033.055-.056.017-.023.034-.047.049-.073.015-.025.029-.05.041-.075s.021-.047.028-.067.01-.035.01-.045c0-.003 0-.006-.001-.007-.001 0-.002-.001-.003-.001-.005 0-.014.009-.028.027-.015.019-.031.043-.05.074s-.037.065-.055.104-.033.078-.046.119z"
                            transform="matrix(24 0 0 24 11.63 18.162)"
                        />
                        <path
                            d="m.173.026c-.023 0-.04-.006-.053-.018s-.02-.028-.02-.047c0-.02.005-.041.014-.063s.019-.044.028-.066c.009-.021.013-.041.013-.058 0-.021-.006-.037-.018-.048-.013-.01-.025-.019-.038-.028-.015.039-.031.077-.048.112s-.03.062-.041.08l-.009-.017c.009-.017.022-.043.037-.076s.029-.071.044-.114c-.007-.007-.01-.015-.01-.024 0-.02.006-.036.017-.049s.02-.019.025-.019c.007 0 .012.003.013.009.001.007.001.012.001.015s-.002.01-.005.019c-.004.01-.006.016-.006.018 0 .009.005.018.016.025.01.008.022.017.035.026.013.01.025.022.036.035.01.014.015.032.015.053 0 .02-.004.04-.012.061-.009.021-.017.04-.025.059-.009.019-.013.035-.013.049 0 .012.003.02.009.024s.013.006.021.006c.016 0 .033-.005.05-.015s.033-.023.049-.038.03-.032.043-.049.022-.032.028-.047l.013.01c-.013.028-.03.056-.052.082-.022.027-.046.05-.073.067s-.055.026-.084.026z"
                            transform="matrix(24 0 0 24 23.054 18.162)"
                        />
                        <path
                            d="m.108.044c-.041 0-.071-.011-.091-.033-.021-.023-.031-.05-.031-.083 0-.03.007-.062.023-.095.015-.033.035-.065.06-.094.024-.029.052-.053.082-.072.03-.018.06-.027.089-.027.015 0 .03.004.043.012s.019.024.019.049-.007.049-.022.071c-.015.023-.035.043-.059.06-.025.017-.052.031-.08.042-.029.011-.058.018-.087.021-.001.007-.002.013-.003.019s-.001.012-.001.017c0 .01.001.02.004.03.002.01.006.019.012.027.006.009.014.015.025.02.01.005.023.007.038.007.029 0 .057-.008.085-.025.028-.016.054-.037.078-.064s.044-.056.061-.088l.012.009c-.018.04-.041.075-.068.104-.028.029-.058.052-.09.069-.033.016-.066.024-.099.024zm-.049-.168c.018-.006.038-.015.061-.027.023-.011.045-.025.066-.041.02-.016.038-.034.052-.053.013-.019.02-.04.02-.061 0-.007-.001-.013-.004-.017s-.008-.006-.017-.006c-.016 0-.033.006-.051.018-.018.013-.035.029-.052.049s-.032.042-.045.066-.023.048-.03.072z"
                            transform="matrix(24 0 0 24 31.406 18.162)"
                        />
                        <path
                            d="m.219.087c-.047 0-.086-.012-.114-.035-.029-.024-.05-.055-.063-.092-.014-.037-.02-.076-.02-.117 0-.053.008-.106.024-.159s.038-.104.066-.153c.027-.048.059-.091.094-.129.035-.037.073-.067.112-.089.039-.021.077-.032.115-.032.027 0 .05.006.067.019s.025.036.025.067c0 .019-.003.041-.01.065-.007.025-.016.048-.027.07-.011.023-.024.041-.037.056-.014.015-.028.022-.042.022-.005 0-.011-.002-.016-.005-.006-.003-.011-.009-.015-.018.01-.011.021-.025.034-.043.012-.017.024-.035.035-.054s.021-.037.028-.056c.007-.018.01-.034.01-.048 0-.013-.004-.024-.011-.033-.008-.008-.021-.012-.038-.012-.029 0-.058.012-.089.035s-.061.054-.09.093-.056.083-.079.131-.042.097-.055.147c-.014.05-.021.098-.021.143 0 .033.005.064.014.093.009.03.024.054.046.073.021.018.048.027.081.027.035 0 .071-.01.106-.03.035-.02.067-.046.096-.079s.053-.068.07-.106l.014.01c-.022.043-.049.083-.08.119-.031.037-.066.066-.105.088-.039.021-.08.032-.125.032z"
                            transform="matrix(24 0 0 24 45.638 18.162)"
                        />
                        <path
                            d="m.048.047c-.021 0-.038-.007-.053-.02-.014-.014-.021-.035-.021-.063 0-.024.005-.05.016-.078.011-.027.025-.055.044-.082s.04-.051.064-.074c.023-.022.048-.04.075-.053.026-.013.052-.02.078-.02s.048.007.065.02c.017.014.026.032.026.055 0 .017-.004.028-.013.034s-.021.009-.036.009c.001-.005.003-.011.004-.018.001-.006.001-.012.001-.017 0-.015-.004-.029-.011-.04s-.02-.016-.038-.016c-.019 0-.038.006-.057.019s-.038.031-.056.052c-.018.022-.034.046-.048.071s-.025.05-.033.075-.012.047-.012.066c0 .026.009.039.027.039.015 0 .032-.007.05-.022.018-.014.037-.032.056-.055.019-.022.037-.045.054-.069s.033-.046.046-.066c.003-.004.004-.006.005-.006.004.001.009.002.016.004.006.002.011.005.016.008s.007.008.007.013c0 .006-.003.014-.009.024-.006.011-.013.023-.02.036s-.014.027-.02.04c-.006.014-.009.027-.009.039 0 .01.003.02.008.029.005.01.014.015.026.015.018 0 .042-.013.071-.04.029-.026.059-.065.089-.118l.01.01c-.014.036-.032.068-.054.095s-.045.049-.07.064-.05.023-.074.023c-.025 0-.043-.007-.054-.022-.012-.015-.018-.031-.018-.048 0-.004 0-.008.001-.014.001-.004.001-.01.002-.015-.029.039-.055.068-.078.087-.024.019-.048.029-.073.029z"
                            transform="matrix(24 0 0 24 57.494 18.162)"
                        />
                        <path
                            d="m.173.026c-.023 0-.04-.006-.053-.018s-.02-.028-.02-.047c0-.02.005-.041.014-.063s.019-.044.028-.066c.009-.021.013-.041.013-.058 0-.021-.006-.037-.018-.048-.013-.01-.025-.019-.038-.028-.015.039-.031.077-.048.112s-.03.062-.041.08l-.009-.017c.009-.017.022-.043.037-.076s.029-.071.044-.114c-.007-.007-.01-.015-.01-.024 0-.02.006-.036.017-.049s.02-.019.025-.019c.007 0 .012.003.013.009.001.007.001.012.001.015s-.002.01-.005.019c-.004.01-.006.016-.006.018 0 .009.005.018.016.025.01.008.022.017.035.026.013.01.025.022.036.035.01.014.015.032.015.053 0 .02-.004.04-.012.061-.009.021-.017.04-.025.059-.009.019-.013.035-.013.049 0 .012.003.02.009.024s.013.006.021.006c.016 0 .033-.005.05-.015s.033-.023.049-.038.03-.032.043-.049.022-.032.028-.047l.013.01c-.013.028-.03.056-.052.082-.022.027-.046.05-.073.067s-.055.026-.084.026z"
                            transform="matrix(24 0 0 24 67.958 18.162)"
                        />
                        <path
                            d="m.069.032c-.029 0-.049-.009-.061-.026-.012-.018-.018-.04-.018-.065 0-.021.003-.042.01-.064s.016-.043.027-.062.023-.035.037-.047c.013-.012.027-.018.041-.018.003 0 .007.001.011.001.003.002.007.003.01.004-.006.005-.013.015-.021.029-.009.015-.017.031-.024.049-.007.019-.013.037-.018.055-.006.018-.008.033-.008.046 0 .02.003.034.01.043s.015.014.026.014c.014 0 .031-.008.05-.023s.038-.034.057-.057c-.019-.015-.033-.036-.043-.061-.011-.024-.016-.05-.016-.076 0-.022.004-.043.012-.062.007-.019.018-.035.033-.047.015-.011.032-.017.053-.017.027 0 .046.007.055.022s.014.033.014.055c0 .026-.006.055-.019.086-.013.032-.029.063-.048.092.012.007.025.01.038.01.011 0 .023-.002.036-.007.013-.004.026-.011.04-.022.013-.011.023-.025.031-.043l.012.01c-.013.031-.032.053-.056.066-.025.013-.048.02-.07.02-.009 0-.017-.001-.025-.003-.008-.001-.016-.004-.023-.007-.023.03-.048.055-.074.075-.027.02-.054.03-.079.03zm.147-.147c.017-.025.031-.051.042-.078s.016-.052.016-.076c0-.019-.003-.034-.008-.043-.006-.009-.013-.013-.022-.013-.013 0-.027.01-.041.029s-.021.044-.021.073c0 .019.003.039.009.058.005.02.014.037.025.05z"
                            transform="matrix(24 0 0 24 76.31 18.162)"
                        />
                        <path
                            d="m.083.03c-.029 0-.049-.01-.061-.029-.013-.02-.019-.045-.019-.074 0-.032.006-.07.018-.114s.028-.09.048-.139c.019-.049.041-.096.064-.143.023-.046.047-.088.07-.126s.045-.068.065-.091c.019-.023.035-.034.046-.034.007 0 .013.004.018.012.005.009.008.019.011.03.003.012.004.023.004.032 0 .018-.004.042-.013.07-.009.029-.022.062-.038.097-.017.036-.036.072-.058.108-.023.037-.047.072-.073.105-.027.033-.055.062-.084.086-.004.018-.007.035-.009.052-.003.017-.004.034-.004.049 0 .026.004.045.011.058s.018.019.033.019c.018 0 .036-.008.054-.024.017-.016.034-.036.05-.061.015-.024.029-.049.04-.075l.015.008c-.027.057-.056.101-.087.134s-.065.05-.101.05zm.007-.244c.023-.023.046-.05.068-.081.022-.03.043-.061.062-.094s.035-.064.05-.095.026-.058.034-.083c.008-.024.012-.043.012-.056 0-.007-.001-.011-.002-.013-.002-.002-.004-.003-.005-.003-.005 0-.014.008-.027.025-.013.018-.029.04-.046.069s-.035.061-.053.098-.035.075-.051.115c-.017.04-.031.079-.042.118z"
                            transform="matrix(24 0 0 24 85.07 18.162)"
                        />
                        <path
                            d="m.072.026c-.026 0-.045-.008-.058-.024-.013-.017-.019-.037-.019-.06 0-.015.003-.035.009-.058.005-.023.013-.048.022-.074.009-.025.02-.049.033-.072.012-.023.025-.041.039-.056.014-.014.028-.021.041-.021.006 0 .011.002.016.005.004.004.006.01.006.017 0 .008-.005.021-.015.039-.011.018-.023.039-.037.062s-.026.048-.036.073c-.011.026-.016.051-.016.074 0 .024.004.04.012.048s.02.012.037.012c.023 0 .047-.011.073-.034.025-.023.052-.062.081-.119l.009.01c-.019.057-.047.101-.083.132-.037.031-.075.046-.114.046zm.12-.458c-.011 0-.02-.003-.028-.008-.009-.005-.013-.014-.013-.025 0-.014.008-.026.025-.037.016-.01.032-.015.048-.015.01 0 .018.002.024.007s.009.013.009.024c0 .013-.007.025-.02.036-.014.012-.029.018-.045.018z"
                            transform="matrix(24 0 0 24 90.734 18.162)"
                        />
                        <path
                            d="m.323.02c-.023 0-.041-.006-.052-.018-.012-.012-.018-.027-.018-.046 0-.014.003-.029.008-.044s.009-.031.014-.048c.005-.016.007-.032.007-.047 0-.019-.004-.032-.011-.039-.008-.007-.018-.01-.029-.01-.021 0-.042.008-.063.025s-.042.039-.062.065c-.02.027-.039.054-.056.082s-.033.053-.046.075c-.011 0-.021-.002-.031-.005-.009-.004-.017-.01-.022-.017 0-.003.004-.014.01-.031.007-.017.016-.039.025-.064s.018-.051.025-.078.011-.051.011-.073c.004-.005.01-.009.019-.014s.018-.007.027-.007c.018 0 .027.009.027.026 0 .011-.003.028-.009.053-.006.026-.014.052-.023.079.018-.027.038-.055.061-.082.022-.027.046-.049.071-.067s.051-.027.076-.027c.026 0 .045.009.057.028.011.019.017.039.017.062 0 .021-.003.04-.01.057-.007.018-.013.035-.02.051-.007.017-.011.033-.011.05 0 .014.003.024.009.029.005.006.012.009.021.009.017 0 .033-.008.05-.024.017-.015.033-.035.049-.059.015-.024.029-.048.041-.073l.011.013c-.014.033-.03.062-.047.087s-.036.045-.057.06-.044.022-.069.022z"
                            transform="matrix(24 0 0 24 96.518 18.162)"
                        />
                        <path
                            d="m.209.087c-.035 0-.066-.01-.094-.029-.029-.02-.051-.048-.068-.086-.017-.037-.025-.081-.025-.133 0-.051.008-.103.024-.156s.038-.103.066-.151c.027-.048.059-.091.094-.129.035-.037.073-.067.112-.089s.077-.033.115-.033c.025 0 .046.005.061.016s.026.024.033.041.01.036.01.056c0 .023-.004.046-.012.068-.008.023-.019.041-.032.056s-.028.022-.044.022c-.005 0-.011-.002-.016-.005-.006-.003-.011-.009-.015-.018.009-.007.02-.019.033-.036.012-.017.023-.037.032-.058s.013-.041.013-.059c0-.015-.004-.028-.013-.038s-.025-.015-.047-.015c-.029 0-.058.012-.089.035s-.061.054-.09.093-.056.083-.079.131-.042.097-.055.147c-.014.05-.021.097-.021.142 0 .061.013.108.038.143.025.034.057.051.096.051.026 0 .051-.009.074-.028.023-.019.044-.044.062-.075.018-.03.033-.063.044-.098s.017-.069.02-.102c-.025-.005-.045-.009-.06-.011-.015-.001-.03-.002-.043-.002-.009 0-.018 0-.027 0-.01.001-.021.001-.034.001.005-.019.013-.033.025-.041.011-.007.026-.011.043-.011.019 0 .038.003.059.009.02.007.04.014.059.021s.036.013.051.017l-.007.032c-.013.033-.023.063-.031.087-.008.025-.014.048-.018.066-.005.019-.008.036-.009.051-.002.015-.003.028-.003.04 0 .013.001.026.002.039s.001.026.002.039c-.011-.005-.02-.01-.028-.016-.009-.007-.015-.015-.019-.024-.005-.009-.007-.02-.007-.032 0-.007.001-.015.002-.022s.002-.015.005-.024c-.024.033-.052.061-.083.084s-.067.034-.106.034z"
                            transform="matrix(24 0 0 24 113.942 18.162)"
                        />
                        <path
                            d="m.083.03c-.029 0-.049-.01-.061-.029-.013-.02-.019-.045-.019-.074 0-.032.006-.07.018-.114s.028-.09.048-.139c.019-.049.041-.096.064-.143.023-.046.047-.088.07-.126s.045-.068.065-.091c.019-.023.035-.034.046-.034.007 0 .013.004.018.012.005.009.008.019.011.03.003.012.004.023.004.032 0 .018-.004.042-.013.07-.009.029-.022.062-.038.097-.017.036-.036.072-.058.108-.023.037-.047.072-.073.105-.027.033-.055.062-.084.086-.004.018-.007.035-.009.052-.003.017-.004.034-.004.049 0 .026.004.045.011.058s.018.019.033.019c.018 0 .036-.008.054-.024.017-.016.034-.036.05-.061.015-.024.029-.049.04-.075l.015.008c-.027.057-.056.101-.087.134s-.065.05-.101.05zm.007-.244c.023-.023.046-.05.068-.081.022-.03.043-.061.062-.094s.035-.064.05-.095.026-.058.034-.083c.008-.024.012-.043.012-.056 0-.007-.001-.011-.002-.013-.002-.002-.004-.003-.005-.003-.005 0-.014.008-.027.025-.013.018-.029.04-.046.069s-.035.061-.053.098-.035.075-.051.115c-.017.04-.031.079-.042.118z"
                            transform="matrix(24 0 0 24 127.238 18.162)"
                        />
                        <path
                            d="m.069.032c-.029 0-.049-.009-.061-.026-.012-.018-.018-.04-.018-.065 0-.021.003-.042.01-.064s.016-.043.027-.062.023-.035.037-.047c.013-.012.027-.018.041-.018.003 0 .007.001.011.001.003.002.007.003.01.004-.006.005-.013.015-.021.029-.009.015-.017.031-.024.049-.007.019-.013.037-.018.055-.006.018-.008.033-.008.046 0 .02.003.034.01.043s.015.014.026.014c.014 0 .031-.008.05-.023s.038-.034.057-.057c-.019-.015-.033-.036-.043-.061-.011-.024-.016-.05-.016-.076 0-.022.004-.043.012-.062.007-.019.018-.035.033-.047.015-.011.032-.017.053-.017.027 0 .046.007.055.022s.014.033.014.055c0 .026-.006.055-.019.086-.013.032-.029.063-.048.092.012.007.025.01.038.01.011 0 .023-.002.036-.007.013-.004.026-.011.04-.022.013-.011.023-.025.031-.043l.012.01c-.013.031-.032.053-.056.066-.025.013-.048.02-.07.02-.009 0-.017-.001-.025-.003-.008-.001-.016-.004-.023-.007-.023.03-.048.055-.074.075-.027.02-.054.03-.079.03zm.147-.147c.017-.025.031-.051.042-.078s.016-.052.016-.076c0-.019-.003-.034-.008-.043-.006-.009-.013-.013-.022-.013-.013 0-.027.01-.041.029s-.021.044-.021.073c0 .019.003.039.009.058.005.02.014.037.025.05zm.125-.299c-.01 0-.019-.002-.026-.007s-.011-.012-.011-.023c0-.013.007-.024.022-.033s.029-.014.044-.014c.009 0 .017.002.022.006s.008.011.008.022-.006.022-.018.033c-.013.011-.026.016-.041.016zm-.144-.021c-.01 0-.019-.002-.026-.007s-.011-.012-.011-.023c0-.013.007-.024.022-.033s.029-.014.044-.014c.009 0 .017.002.022.006s.008.011.008.022-.006.022-.018.033c-.013.011-.026.016-.041.016z"
                            transform="matrix(24 0 0 24 132.902 18.162)"
                        />
                        <path
                            d="m.107.056c-.032 0-.059-.011-.08-.032-.021-.021-.032-.053-.032-.096 0-.033.007-.066.02-.098.013-.031.03-.06.051-.085s.045-.046.071-.061.052-.022.077-.022c.027 0 .046.008.056.024s.015.033.015.05c0 .019-.004.036-.013.051s-.022.022-.037.022c-.011 0-.021-.005-.029-.015.01-.005.019-.016.026-.033.007-.016.011-.031.011-.046 0-.008-.001-.014-.004-.019-.003-.004-.009-.006-.018-.006-.018 0-.036.008-.055.024s-.037.037-.053.062c-.017.025-.03.052-.04.08-.011.028-.016.054-.016.079 0 .021.005.04.015.057s.029.026.058.026c.027 0 .054-.007.081-.022s.052-.036.075-.063.042-.059.056-.096l.013.01c-.015.04-.036.076-.063.107-.027.032-.056.057-.088.075s-.064.027-.097.027z"
                            transform="matrix(24 0 0 24 141.758 18.162)"
                        />
                        <path
                            d="m.297.038c-.019 0-.035-.006-.048-.019s-.024-.029-.031-.048c-.008-.019-.014-.039-.017-.06-.004-.02-.006-.038-.006-.053.007 0 .018-.002.032-.006.014-.003.028-.009.043-.017.015-.007.027-.017.038-.03.01-.012.015-.026.015-.043 0-.014-.005-.025-.015-.033s-.022-.012-.035-.012c-.021 0-.04.007-.058.021-.018.015-.034.033-.048.056-.015.023-.028.048-.039.074s-.021.051-.029.074-.015.043-.02.058c-.004.012-.01.02-.017.025-.007.004-.015.006-.023.006-.011 0-.019-.005-.024-.014s-.008-.021-.009-.036c-.001-.014-.002-.029-.002-.044 0-.035.006-.075.017-.12s.027-.093.046-.142.039-.096.062-.142.045-.088.068-.125.044-.067.063-.089.035-.033.047-.033c.009 0 .017.006.023.019.005.013.008.028.008.046 0 .023-.005.048-.015.076-.01.029-.024.057-.042.086-.019.029-.04.056-.065.081s-.053.047-.082.065c-.022.054-.038.106-.049.157-.011.052-.017.094-.017.129.011-.027.024-.056.039-.088s.032-.063.051-.092.04-.053.063-.072.046-.028.071-.028.046.009.063.026.026.038.026.063c0 .021-.005.04-.016.054-.011.015-.025.027-.04.036-.016.009-.032.016-.047.02-.016.005-.029.007-.039.007.001.005.003.015.006.029s.008.029.015.044c.006.015.014.028.023.039s.021.017.034.017c.019 0 .038-.007.055-.022s.034-.034.05-.059c.015-.024.03-.051.043-.082l.01.01c-.009.033-.024.063-.043.092s-.041.053-.064.072c-.024.018-.048.027-.071.027zm-.146-.419c.031-.025.058-.053.081-.084s.04-.062.053-.091c.013-.028.019-.051.019-.068 0-.003 0-.006 0-.007-.001-.001-.002-.002-.003-.002-.007 0-.018.012-.034.035-.017.023-.035.054-.056.092-.021.039-.041.08-.06.125z"
                            transform="matrix(24 0 0 24 149.51 18.162)"
                        />
                        <path
                            d="m.083.03c-.029 0-.049-.01-.061-.029-.013-.02-.019-.045-.019-.074 0-.032.006-.07.018-.114s.028-.09.048-.139c.019-.049.041-.096.064-.143.023-.046.047-.088.07-.126s.045-.068.065-.091c.019-.023.035-.034.046-.034.007 0 .013.004.018.012.005.009.008.019.011.03.003.012.004.023.004.032 0 .018-.004.042-.013.07-.009.029-.022.062-.038.097-.017.036-.036.072-.058.108-.023.037-.047.072-.073.105-.027.033-.055.062-.084.086-.004.018-.007.035-.009.052-.003.017-.004.034-.004.049 0 .026.004.045.011.058s.018.019.033.019c.018 0 .036-.008.054-.024.017-.016.034-.036.05-.061.015-.024.029-.049.04-.075l.015.008c-.027.057-.056.101-.087.134s-.065.05-.101.05zm.007-.244c.023-.023.046-.05.068-.081.022-.03.043-.061.062-.094s.035-.064.05-.095.026-.058.034-.083c.008-.024.012-.043.012-.056 0-.007-.001-.011-.002-.013-.002-.002-.004-.003-.005-.003-.005 0-.014.008-.027.025-.013.018-.029.04-.046.069s-.035.061-.053.098-.035.075-.051.115c-.017.04-.031.079-.042.118z"
                            transform="matrix(24 0 0 24 160.214 18.162)"
                        />
                        <path
                            d="m.108.044c-.041 0-.071-.011-.091-.033-.021-.023-.031-.05-.031-.083 0-.03.007-.062.023-.095.015-.033.035-.065.06-.094.024-.029.052-.053.082-.072.03-.018.06-.027.089-.027.015 0 .03.004.043.012s.019.024.019.049-.007.049-.022.071c-.015.023-.035.043-.059.06-.025.017-.052.031-.08.042-.029.011-.058.018-.087.021-.001.007-.002.013-.003.019s-.001.012-.001.017c0 .01.001.02.004.03.002.01.006.019.012.027.006.009.014.015.025.02.01.005.023.007.038.007.029 0 .057-.008.085-.025.028-.016.054-.037.078-.064s.044-.056.061-.088l.012.009c-.018.04-.041.075-.068.104-.028.029-.058.052-.09.069-.033.016-.066.024-.099.024zm-.049-.168c.018-.006.038-.015.061-.027.023-.011.045-.025.066-.041.02-.016.038-.034.052-.053.013-.019.02-.04.02-.061 0-.007-.001-.013-.004-.017s-.008-.006-.017-.006c-.016 0-.033.006-.051.018-.018.013-.035.029-.052.049s-.032.042-.045.066-.023.048-.03.072z"
                            transform="matrix(24 0 0 24 165.878 18.162)"
                        />
                    </g>
                </svg>
            </div>
        </div>
        <div class="col-lg-5 mt-4 mt-lg-0">
            <img
                class="img-fluid"
                src="/assets/media/zentrale-gloeckle.jpg"
                alt="Zentrale der Glöckle GmbH"
            />
        </div>
    </section>
    <section class="c-cta-job-offers | o-section-shaded">
        <header class="o-hgroup">
            <p class="o-kicker">Verschaffen Sie sich einen &Uuml;berblick</p>
            <h2>Unsere Stellenangebote</h2>
        </header>
        <div class="o-richtext">
            Sie haben das Gef&uuml;hl, dass wir Sie brauchen, auch wenn keine
            f&uuml;r Sie passende Stelle ausgeschrieben ist? Sie haben die
            M&ouml;glichkeit, sich
            <a [href]="environment.externalUrls.initiativeApplicationUrl">
                initiativ
            </a>
            zu bewerben.
        </div>

        <a
            class="btn btn-primary | c-cta-job-offers__button"
            [routerLink]="['/stellenangebote/']"
            >Zu den Stellenangeboten</a
        >
    </section>
    <section class="o-row-space row justify-content-center">
        <div class="col-12">
            <header class="o-hgroup">
                <p class="o-kicker">
                    Darauf k&ouml;nnen Sie sich jetzt schon freuen
                </p>
                <h2>Unsere Mitarbeitervorteile</h2>
            </header>
        </div>
        <div class="col-12">
            <div class="o-card o-card--icon:left">
                <div class="o-card__icon logo-gptw">
                    <img src="../../../assets/images/logo_gptw_certified.png" />
                </div>
                <div class="o-richtext">
                    <p>
                        Die Unternehmensgruppe Glöckle wurde von "Great Place to
                        Work®" offiziell als attraktiver Arbeitgeber
                        zertifiziert. Belegt wurde dies durch eine
                        Mitarbeiterbefragung, bei der wir mit der Note "sehr
                        gut" abgeschnitten haben. Damit hat unsere
                        Unternehmensgruppe die zweithöchste von sieben möglichen
                        Bewertungsstufen erreicht und sich das offizielle
                        Zertifikat gesichert. Das Zertifikat ist für uns
                        Motivation und Ansporn zugleich, den erfolgreich
                        eingeschlagenen Weg beizubehalten und diesen gemeinsam
                        weiterzugehen.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="o-card o-card--icon:left">
                <div class="o-card__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-tools"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4" />
                        <line x1="14.5" y1="5.5" x2="18.5" y2="9.5" />
                        <polyline points="12 8 7 3 3 7 8 12" />
                        <line x1="7" y1="8" x2="5.5" y2="9.5" />
                        <polyline points="16 12 21 17 17 21 12 16" />
                        <line x1="16" y1="17" x2="14.5" y2="18.5" />
                    </svg>
                </div>
                <div class="o-richtext">
                    <p>
                        Die Fort- und Weiterbildung unserer Mitarbeiter liegt
                        uns sehr am Herzen. Deshalb unterstützen wir Sie bei
                        Ihrer fachlichen und persönlichen Weiterentwicklung
                        gerne. Dies ist ein Teil des Erfolgsrezepts unserer
                        Unternehmensgruppe. Das steht fest!
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="o-card o-card--icon:left">
                <div class="o-card__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-bike"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="5" cy="18" r="3" />
                        <circle cx="19" cy="18" r="3" />
                        <polyline points="12 19 12 15 9 12 14 8 16 11 19 11" />
                        <circle cx="17" cy="5" r="1" />
                    </svg>
                </div>
                <div class="o-richtext">
                    <p>
                        Auch wir finden es eine gute Sache, dass unsere
                        Mitarbeiter mehr mit dem Fahrrad unterwegs sind. Deshalb
                        bieten wir auch die Möglichkeit, ein Fahrrad über den
                        Anbieter JobRad zu leasen. Wir freuen uns über jeden
                        weiteren Radler im Team Glöckle.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="o-card o-card--icon:left">
                <div class="o-card__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-run"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="13" cy="4" r="1" />
                        <path d="M4 17l5 1l.75 -1.5" />
                        <path d="M15 21l0 -4l-4 -3l1 -6" />
                        <path d="M7 12l0 -3l5 -1l3 3l3 1" />
                    </svg>
                </div>
                <div class="o-richtext">
                    <p>
                        Dass unsere Mitarbeiter gesund bleiben, ist uns ein
                        wichtiges Anliegen. Darum haben wir uns der Initiative -
                        Gesunder Betrieb (i-gb) angeschlossen. Somit kommen alle
                        Mitarbeiter in den Genuss vieler Leistungen und Angebote
                        des Gesundheitsnetzes.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="o-card o-card--icon:left">
                <div class="o-card__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-swimming"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="16" cy="9" r="1" />
                        <path d="M6 11l4 -2l3.5 3l-1.5 2" />
                        <path
                            d="M3 16.75a2.4 2.4 0 0 0 1 .25a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 1 -.25"
                        />
                    </svg>
                </div>
                <div class="o-richtext">
                    <p>
                        Wer wünscht sich nicht nach einem heißen und
                        anstrengenden Arbeitstag im Sommer eine kleine
                        Abkühlung? Als Mitarbeiter von Glöckle - kein Problem!
                        Hierfür steht allen Mitarbeitern der firmeneigenen
                        Glöckle-See zur Verfügung.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="c-facts | o-section-shaded o-section-shaded--medium">
        <div class="row mb-5">
            <header class="col-12 text-center">
                <h2>Die Unternehmensgruppe Gl&ouml;ckle auf einen Blick</h2>
            </header>
        </div>

        <div class="row | o-row-space-medium">
            <div class="col-sm-6 row">
                <div class="col-12 col-lg-5 | mb-4 mb-lg-0">
                    <div class="c-facts__media">
                        <img
                            class="img-fluid"
                            src="/assets/media/under_construction.svg"
                            alt="Mitarbeiter"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-7">
                    <div class="o-hgroup">
                        <h3>Mitarbeiter<br /><small>ca. 490</small></h3>
                    </div>
                    <div class="o-richtext">
                        <p>Ein starkes, stetig wachsendes Team.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 row">
                <div class="col-12 col-lg-5 | mb-4 mb-lg-0">
                    <div class="c-facts__media">
                        <img
                            class="img-fluid"
                            src="/assets/media/printing_invoices.svg"
                            alt="Umsatz, Rechnungen drucken "
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-7">
                    <div class="o-hgroup">
                        <h3>Umsatz<br /><small>ca. 150 Mio. Euro</small></h3>
                    </div>
                    <div class="o-richtext">
                        <p>Der Erfolg einer starken Mannschaft.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 row">
                <div class="col-12 col-lg-5 | mb-4 mb-lg-0">
                    <div class="c-facts__media">
                        <img
                            class="img-fluid"
                            src="/assets/media/contrast.svg"
                            alt="Dokumente im Kontrast"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-7">
                    <div class="o-hgroup">
                        <h3>
                            Auftragsgr&ouml;&szlig;e je Einzelauftrag<br /><small
                                >&lt; 35 Mio. Euro</small
                            >
                        </h3>
                    </div>
                    <div class="o-richtext">
                        <p>
                            Egal wie klein ein Auftrag ist, er wird als Team
                            gemeistert.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 row">
                <div class="col-12 col-lg-5 | mb-4 mb-lg-0">
                    <div class="c-facts__media">
                        <img
                            class="img-fluid"
                            src="/assets/media/by_the_road.svg"
                            alt="Person an einer Straße"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-7">
                    <div class="o-hgroup">
                        <h3>
                            Aktionsradius<br /><small>deutschlandweit</small>
                        </h3>
                    </div>
                    <div class="o-richtext">
                        <p>
                            Wir agieren ortsunabhängig, jedoch mit Schwerpunkten
                            im süddeutschen Raum.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
