import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpEventType} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {faEuroSign, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {IStellenangebot} from 'src/app/interfaces/stellenangebot';
import {IBewerbung} from 'src/app/interfaces/bewerbung';
import {BewerbungenService} from 'src/app/services/bewerbungen/bewerbungen.service';
import {MetaTagsService} from 'src/app/services/metaTags/meta-tags.service';
import {StellenangeboteService} from 'src/app/services/stellenangebote/stellenangebote.service';
import * as moment from 'moment';

@Component({
  selector: 'app-bewerben',
  templateUrl: './bewerben.component.html',
  styleUrls: ['./bewerben.component.css']
})
export class BewerbenComponent implements OnInit, OnDestroy {
  faEuroSign = faEuroSign;
  faTimesCircle = faTimesCircle;
  bewerbenFormErrorMessage: string;
  birthdayMaxDate = moment();
  birthdayMinDate = moment('1900-01-02');
  dateien = [];
  dateienErrorMessage: string;
  dateienErrorMessageArray: string[] = [];
  moment = moment;
  quelleKeineAngabe = 'Keine Angabe';
  stellenangebotId: string = this.route.snapshot.paramMap.get('stellenangebotId');
  stellenangebot: IStellenangebot;
  formSubmitted = false;
  formSubmitActive = false;
  metaTagRobotsContent: string;

  bewerbenForm = this.formBuilder.group({
    Anrede: ['', Validators.required],
    Titel: ['', Validators.maxLength(255)],
    Vorname: ['', [Validators.maxLength(255), Validators.required]],
    Name: ['', [Validators.maxLength(255), Validators.required]],
    Strasse: ['', [Validators.maxLength(255), Validators.required]],
    Hausnummer: ['', [Validators.maxLength(10), Validators.required]],
    PLZ: ['', [Validators.maxLength(10), Validators.required]],
    Ort: ['', [Validators.maxLength(255), Validators.required]],
    EMail: ['', [Validators.email, Validators.maxLength(255), Validators.required]],
    Telefon: ['', [Validators.maxLength(255), Validators.required, Validators.pattern('^[0-9\(\)\+\-\/\ ]*$')]],
    Geburtsdatum: ['', Validators.maxLength(10)],
    Gehaltsvorstellung: ['', Validators.maxLength(255)],
    Bemerkung: ['', Validators.maxLength(255)],
    Quelle: [''],
    DatenschutzOk: ['', Validators.requiredTrue],
    ErweiterteDatenverwendung: ['']
  });

  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private recaptchaV3Service: ReCaptchaV3Service, private meta: Meta, private title: Title, private stellenangeboteService: StellenangeboteService, private bewerbungenService: BewerbungenService, private metaTagsService: MetaTagsService) {
  }

  ngOnInit(): void {
    this.metaTagRobotsContent = this.metaTagsService.getMetaTagContent('robots'); // Save robots meta tag for reset on page destroy
    this.metaTagsService.updateMetaTagContent('robots', 'noindex');

    this.stellenangeboteService.getStellenangebot(this.stellenangebotId)
      .subscribe((response: IStellenangebot) => {
        if (response) {
          this.stellenangebot = response;

          this.title.setTitle('Glöckle Bewerberportal - Bewerbung - ' + this.stellenangebot.Titel);
          this.meta.updateTag({
            name: 'description',
            content: 'Glöckle Stellenangebot - Bewerben Sie sich jetzt als "' + this.stellenangebot.Titel + '"'
          });
        } else {
          this.router.navigate(['/404']);
        }
      });
  }

  ngOnDestroy(): void {
    this.metaTagsService.updateMetaTagContent('robots', this.metaTagRobotsContent);
  }

  onSubmit() {
    this.formSubmitted = true;
    this.formSubmitActive = true;

    try {
      // Validieren der einzelnen Dateigrößen
      if (this.dateienErrorMessageArray.length > 0) {
        let dateienErrorMessageArrayFilled = false;
        this.dateienErrorMessageArray.forEach(dateienErrorMessage => { // Muss durchlaufen werden, da einzelne Array-Items bei Erfolg zwar vorhanden, aber "empty" sind
          if (dateienErrorMessage.length > 0) {
            dateienErrorMessageArrayFilled = true;
          }
        });
        if (dateienErrorMessageArrayFilled) {
          this.formSubmitActive = false;
          return false; // Fehlermeldung wird bereits angezeigt
        }
      }

      // Validieren der Gesamt-Dateigröße
      let dateiGroesseGesamt = 0;
      this.dateien.forEach(datei => {
        if (datei)
          dateiGroesseGesamt += datei.size;
      });
      if (dateiGroesseGesamt > 1024 * 1024 * 10 * 5) { // max. 50 MB
        this.dateienErrorMessage = 'Die hochgeladenen Dateien übersteigen das maximal zulässige Limit.';
        this.formSubmitActive = false;
        return false;
      } else
        this.dateienErrorMessage = '';

      // Validieren der Dateianzahl
      if (dateiGroesseGesamt == 0 || this.dateien.length < 1) {
        this.dateienErrorMessage = 'Bitte laden Sie mindestens eine Datei hoch.';
        this.formSubmitActive = false;
        return false;
      } else
        this.dateienErrorMessage = '';

      if (this.bewerbenForm.valid) {
        const bewerbung: IBewerbung = {
          StellenangebotId: this.stellenangebotId,
          Anrede: this.bewerbenForm.get('Anrede').value.trim(),
          Titel: this.bewerbenForm.get('Titel').value.trim(),
          Vorname: this.bewerbenForm.get('Vorname').value.trim(),
          Name: this.bewerbenForm.get('Name').value.trim(),
          Strasse: this.bewerbenForm.get('Strasse').value.trim(),
          Hausnummer: this.bewerbenForm.get('Hausnummer').value.trim(),
          PLZ: this.bewerbenForm.get('PLZ').value.trim(),
          Ort: this.bewerbenForm.get('Ort').value.trim(),
          EMail: this.bewerbenForm.get('EMail').value.trim(),
          Telefon: this.bewerbenForm.get('Telefon').value.trim(),
          Geburtsdatum: this.bewerbenForm.get('Geburtsdatum').value ? moment(this.bewerbenForm.get('Geburtsdatum').value)
            .format('YYYY-MM-DD') : null,
          Gehaltsvorstellung: this.bewerbenForm.get('Gehaltsvorstellung').value.trim(),
          Bemerkung: this.bewerbenForm.get('Bemerkung').value.trim(),
          Quelle: this.bewerbenForm.get('Quelle').value.trim().length > 0
            ? this.bewerbenForm.get('Quelle').value.trim()
            : this.quelleKeineAngabe,
          DatenschutzOk: !!this.bewerbenForm.get('DatenschutzOk').value,
          ErweiterteDatenverwendung: !!this.bewerbenForm.get('ErweiterteDatenverwendung').value,
          Dateien: null
        };

        //console.log("Bewerbungsdaten: ", bewerbung);
        this.dateien.forEach(function (datei) {
          if (datei) {
            if (bewerbung.Dateien == null)
              bewerbung.Dateien = [{Name: datei.name, Groesse: datei.size}];
            else
              bewerbung.Dateien.push({Name: datei.name, Groesse: datei.size});
          }
        });
        console.log('Bewerbung mit Dateien: ', JSON.stringify(bewerbung));

        this.recaptchaV3Service.execute('importantAction')
          .subscribe(googleRecaptchaToken => {
            this.bewerbungenService.saveBewerbung(bewerbung)
              .subscribe((response: any) => {
                if (response.status) {
                  console.log(
                    'Die Bewerbungsdaten wurden erfolgreich eingereicht, der Dateiupload beginnt.',
                    this.bewerbenForm.value
                  );

                  let dateienUpload = [];
                  this.dateien.forEach(datei => {
                    dateienUpload.push(datei);
                  })
                  let i = 0;
                  dateienUpload.forEach(datei => {
                    i++;
                    console.log('Durchlauf:', i);
                    if (datei) {
                      this.bewerbungenService.getBewerbungPresignedUrl(
                        response.uuid,
                        i + '_' + datei.name,
                        datei.size,
                        googleRecaptchaToken
                      )
                        .subscribe(eventPresignedUrl => {
                          if (eventPresignedUrl.status) {
                            this.bewerbungenService.uploadBewerbungsdatei(eventPresignedUrl.url, datei)
                              .subscribe(eventUploadBewerbungsdatei => {
                                if (eventUploadBewerbungsdatei.type === HttpEventType.UploadProgress) {
                                  console.log('Uploadstatus', eventUploadBewerbungsdatei);
                                }

                                if (eventUploadBewerbungsdatei.type === HttpEventType.Response) {
                                  console.log('Upload erfolgreich', eventUploadBewerbungsdatei.body);

                                  // TODO Sicherstellen, dass ALLE Dateien hochgeladen wurden (aktuell kann es sein, dass die letzte Datei bereits hochgeladen wurde, die vorherigen jedoch noch nicht)
                                  // Wenn die letzte Datei erfolgreich hochgeladen wurde
                                  if (i === dateienUpload.length) {
                                    this.router.navigate(['/stellenangebote/' + this.stellenangebot.ID + '/bewerben-ok/' + response.uuid + '/' + this.stellenangebot.Titel_Url]);
                                  }
                                }
                              });
                          } else {
                            this.bewerbenFormErrorMessage = eventPresignedUrl.message;
                            this.formSubmitActive = false;
                            i = this.dateien.length;
                          }
                        });
                    } else {
                      // Wenn die letzte Datei erfolgreich hochgeladen wurde
                      if (i === dateienUpload.length) {
                        this.router.navigate(['/stellenangebote/' + this.stellenangebot.ID + '/bewerben-ok/' + response.uuid + '/' + this.stellenangebot.Titel_Url]);
                      }
                    }
                  });
                } else {
                  this.bewerbenFormErrorMessage = response.message;
                  this.formSubmitActive = false;
                }
              });
          });
      } else {
        this.formSubmitActive = false;
        window.scroll(0, 0);
      }
      return false;
    } catch (error) {
      console.error(error);
      this.bewerbenFormErrorMessage = 'Beim Übermitteln der Bewerbung ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
      this.formSubmitActive = false;
      return false;
    }
  }

  handleFileSelect(event, id: string, dateiPosition: number) {
    console.log(event.item(0));
    if (event.item(0)) {
      this.dateien[dateiPosition] = event.item(0);
      this.dateienErrorMessage = '';
      let dateiErweiterungenErlaubt = ['.pdf', '.jpg', '.jpeg'];
      let dateiErweiterung = this.dateien[dateiPosition].name.substr(this.dateien[dateiPosition].name.lastIndexOf('.'))
        .toLowerCase();

      if (this.dateien[dateiPosition] && !dateiErweiterungenErlaubt.includes(dateiErweiterung)) {
        this.dateienErrorMessageArray[dateiPosition] = 'Die Datei hat ein ungültiges Dateiformat.';
      } else {
        if (this.dateien[dateiPosition] && this.dateien[dateiPosition].size > 1024 * 1024 * 10) { // max. 10 MB
          this.dateienErrorMessageArray[dateiPosition] = 'Die hochgeladene Datei ist zu groß.';
        } else {
          this.dateienErrorMessageArray.splice(dateiPosition, 1);
        }
      }
    } else {
      this.resetFileInputValue(id, dateiPosition);
    }
  }

  resetFileInputValue(id: string, dateiPosition: number): void {
    try {
      delete this.dateien[dateiPosition];
      delete this.dateienErrorMessageArray[dateiPosition];
      let input = <HTMLInputElement>document.getElementById(id)
      input.value = '';
    } catch {
      console.log('Beim Entfernen der Dateizuweisung ist ein Fehler aufgetreten.');
    }
    console.log(this.dateien);
  }

  validateFormControl(formGroup: FormGroup, formControlName): boolean {
    if (formGroup.controls.hasOwnProperty(formControlName)) {
      if (!formGroup.controls[formControlName].touched && !this.formSubmitted)
        return true;
      return formGroup.controls[formControlName].valid;
    }
    return false;
  }
}
