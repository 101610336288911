<main class="container-fluid">
    <div
        *ngIf="loading"
        class="text-center mt-4 text-primary"
    >
        <div
            class="spinner-grow m-5"
            style="width: 3rem; height: 3rem;"
            role="status"
        >
        </div>
    </div>

    <ng-container *ngIf="stellenangebot">
        <header
            class="c-page-header | row" [ngStyle]="{'background-image': 'linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, .75)), url(/assets/images/' + headerImageName + ')'}"
        >
            <div class="c-page-header__body">
                <h1>{{ stellenangebot.Titel }}</h1>

                <div class="c-page-header__tags">
                    <mat-chip-list>
                        <mat-chip *ngIf="stellenangebot.JobKategorie" disableRipple disabled>{{ stellenangebot.JobKategorie }}</mat-chip>
                        <mat-chip *ngIf="stellenangebot.JobLevel" disableRipple disabled>{{ stellenangebot.JobLevel }}</mat-chip>
                        <mat-chip *ngIf="stellenangebot.Standort.Ort" disableRipple disabled>{{ stellenangebot.Standort.Ort }}</mat-chip>
                        <mat-chip *ngIf="stellenangebot.JobTyp" disableRipple disabled>{{ stellenangebot.JobTyp }}</mat-chip>
                        <mat-chip *ngIf="stellenangebot.JobLevel !== 'Ausbildung'" disableRipple disabled>ab sofort</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </header>
        <section class="row justify-content-center">
            <div class="col-12 col-lg-8">
                <div class="my-4">
                    <app-copytoclipboard
                        [clipboardText]="getUrl()"
                        tooltipText="Link dieses Stellenangebots in die Zwischenablage kopieren"
                    ></app-copytoclipboard>
                </div>

                <div class="h2 mt-4">{{ stellenangebot.Ueberschrift }}</div>

                <div class="o-richtext">
                    <p [innerHTML]="stellenangebot.Vorwort"></p>
                    <h3>{{ stellenangebot.Aufgaben_Titel }}</h3>
                    <p [innerHTML]="stellenangebot.Aufgaben_Detail"></p>
                    <h3>{{ stellenangebot.Profil_Titel }}</h3>
                    <p [innerHTML]="stellenangebot.Profil_Detail"></p>
                    <h3>{{ stellenangebot.Angebot_Titel }}</h3>
                    <p [innerHTML]="stellenangebot.Angebot_Detail"></p>
                    <p [innerHTML]="stellenangebot.Schlusswort"></p>
                </div>
            </div>
        </section>

        <div class="row justify-content-center">
            <section class="c-cta-job-offers c-cta-job-offers--space-medium | o-section-shaded | col-lg-10">
                <header class="o-hgroup">
                    <p class="o-kicker">Wir haben Ihr Interesse geweckt?</p>
                    <h2>Dann freuen wir uns auf Ihre aussagefähige Bewerbung</h2>
                </header>
                <div class="o-richtext">
                    <p *ngIf="stellenangebot.Ansprechpartner">
                        {{ stellenangebot.Firma }}<br />
                        {{ stellenangebot.Ansprechpartner.Name }}
                        <ng-container *ngIf="stellenangebot.Ansprechpartner.Strasse">
                            <br>
                            {{ stellenangebot.Ansprechpartner.Strasse }} {{ stellenangebot.Ansprechpartner.Hausnummer }}<br>
                            {{ stellenangebot.Ansprechpartner.PLZ }} {{ stellenangebot.Ansprechpartner.Ort }}
                        </ng-container>
                        <ng-container *ngIf="stellenangebot.Ansprechpartner.Telefon">
                            <br>
                            Telefon: {{ stellenangebot.Ansprechpartner.Telefon }}<br>
                        </ng-container>
                        <ng-container *ngIf="stellenangebot.Ansprechpartner.EMail">
                            <br>
                            <a [href]="'mailto:'+stellenangebot.Ansprechpartner.EMail">{{ stellenangebot.Ansprechpartner.EMail }}</a>
                        </ng-container>
                    </p>
                </div>

                <a
                    class="btn btn-primary | c-cta-job-offers__button"
                    [routerLink]="['/stellenangebote/' + stellenangebot.ID + '/bewerben/' + stellenangebot.Titel_Url]"
                >
                    Jetzt bewerben
                </a>
            </section>
        </div>
    </ng-container>
</main>
