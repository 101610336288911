import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class JobfilterService {
    public jobkategorie$: Observable<string>;
    private _jobkategorie$: BehaviorSubject<string>;
    public joblevel$: Observable<string>;
    private _joblevel$: BehaviorSubject<string>;
    public jobstandort$: Observable<string>;
    private _jobstandort$: BehaviorSubject<string>;

    private sessionStorageJobkategorie = 'kategorie';
    private sessionStorageJoblevel = 'level';
    private sessionStorageJobstandort = 'standort';

    constructor() {
        this._jobkategorie$ = new BehaviorSubject(sessionStorage.getItem(this.sessionStorageJobkategorie) ? sessionStorage.getItem(this.sessionStorageJobkategorie) : null);
        this.jobkategorie$ = this._jobkategorie$.asObservable();

        this._joblevel$ = new BehaviorSubject(sessionStorage.getItem(this.sessionStorageJoblevel) ? sessionStorage.getItem(this.sessionStorageJoblevel) : null);
        this.joblevel$ = this._joblevel$.asObservable();

        this._jobstandort$ = new BehaviorSubject(sessionStorage.getItem(this.sessionStorageJobstandort) ? sessionStorage.getItem(this.sessionStorageJobstandort) : null);
        this.jobstandort$ = this._jobstandort$.asObservable();
    }

    setJobkategorie(jobkategorie: string) {
        this._jobkategorie$.next(jobkategorie);
        if (jobkategorie) {
            sessionStorage.setItem(this.sessionStorageJobkategorie, jobkategorie);
        } else {
            sessionStorage.removeItem(this.sessionStorageJobkategorie);
        }
    }

    setJoblevel(joblevel: string) {
        this._joblevel$.next(joblevel);
        if (joblevel) {
            sessionStorage.setItem(this.sessionStorageJoblevel, joblevel);
        } else {
            sessionStorage.removeItem(this.sessionStorageJoblevel);
        }
    }

    setJobstandort(jobstandort: string) {
        this._jobstandort$.next(jobstandort);
        if (jobstandort) {
            sessionStorage.setItem(this.sessionStorageJobstandort, jobstandort);
        } else {
            sessionStorage.removeItem(this.sessionStorageJobstandort);
        }
    }
}
