import { Injectable } from '@angular/core';
import { IStellenangebot } from 'src/app/interfaces/stellenangebot';

@Injectable({
    providedIn: 'root'
})
export class JobstandortService {

    constructor() { }

    getJobstandorte(stellenangebote: IStellenangebot[]): string[] {
        let jobstandorte: string[] = [];

        stellenangebote.forEach(stellenangebot => {
            if (stellenangebot.Standort.Ort && stellenangebot.Standort.Ort && stellenangebot.Standort.Ort.length > 0 && !jobstandorte.includes(stellenangebot.Standort.Ort))
            jobstandorte.push(stellenangebot.Standort.Ort);
        });
        jobstandorte.sort();

        return jobstandorte;
    }
}
