import { Component, OnInit } from '@angular/core';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-studium',
    templateUrl: './studium.component.html',
    styleUrls: ['./studium.component.css']
})
export class StudiumComponent implements OnInit {
    faAddressCard = faAddressCard;

    constructor() { }

    ngOnInit(): void {
    }
}
