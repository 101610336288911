import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

interface MetaTagsInterface {
    title: string;
    description: string;
    imageName?: string;
}

@Injectable({
    providedIn: 'root',
})
export class MetaTagsService {
    constructor(
        private titleService: Title,
        private metaService: Meta,
        private router: Router
    ) {}

    public getMetaTagContent(name: string): string {
        var metaTag = this.metaService.getTag("name='" + name + "'");
        if (metaTag) return metaTag.content;
        else return null;
    }

    public setMetaTags({
        title,
        description,
        imageName = 'header-Standard.jpg',
    }: MetaTagsInterface): void {
        const routerUrl =
            this.router.url.charAt(0) === '/'
                ? this.router.url.substr(1)
                : this.router.url; // Remove leading slash
        const imageUrl = `${environment.websiteUrl}assets/images/` + imageName;

        // Title
        this.titleService.setTitle(title);
        this.metaService.updateTag({
            property: 'og:title',
            content: title,
        });

        // Description
        this.metaService.updateTag({
            name: 'description',
            content: description,
        });

        this.metaService.updateTag({
            property: 'og:description',
            content: description,
        });

        // Url
        this.metaService.updateTag({
            property: 'og:url',
            content: `${environment.websiteUrl}${routerUrl}`,
        });

        // Image
        this.metaService.updateTag({
            property: 'og:image',
            content: imageUrl,
        });

        this.metaService.updateTag({
            property: 'og:image:secure_url',
            content: imageUrl,
        });

        // Twitter
        this.metaService.updateTag({
            name: 'twitter:card',
            content: 'summary_large_image',
        });

        // Other
        this.metaService.updateTag({
            property: 'og:type',
            content: 'website',
        });
    }

    public updateMetaTagContent(name: string, content: string): void {
        this.metaService.updateTag({ content: content }, "name='" + name + "'");
    }
}
