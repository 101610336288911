
<main class="container-fluid | o-sections-space">
    <header class="c-page-header c-page-header--image-ueberuns | row">
        <div class="c-page-header__body">
            <h1>Studium / Praktikum</h1>
        </div>
    </header>

    <section class="c-bodytext c-bodytext--default">
        <header class="o-hgroup">
            <h2>Dein duales Studium bei Gl&ouml;ckle</h2>
        </header>
        <div class="o-richtext">
            <p>Uns ist es ein großes Anliegen unsere Fach- und Führungskräfte von morgen selbst im Unternehmen auszubilden. Dabei legen wir natürlich Wert auf eine fachlich und persönlich gute Ausbildung.</p>
            <p>Bei der Unternehmensgruppe Glöckle bieten wir aktuell duale Studiengänge im technischen Bereich an. Die Betreuung der Studierenden findet durch einen Ansprechpartner statt, der durch die fachlichen Ausbilder in den einzelnen Bereichen unterstützt wird.</p>  </div>
    </section>
    <section class="c-cta-job-offers | o-section-shaded">
        <header class="o-hgroup">
            <p class="o-kicker">Suche Dir das Studium, das zu Dir passt!</p>
            <h2>Du m&ouml;chtest gerne bei uns Dein Studium starten?</h2>
        </header>
        <div class="o-richtext">
        </div>



        <a class="btn btn-primary | c-cta-job-offers__button" [routerLink]="['/stellenangebote']" [queryParams]="{level: 'Student'}">
            Zu den Stellenangeboten
        </a>
    </section>
</main>
