<main class="container-fluid">
    <header class="c-page-header c-page-header--image-stellenangebote | row">
        <div class="c-page-header__body">
            <h1>{{ stellenangebot?.Titel }}</h1>
        </div>
    </header>
    <section class="row justify-content-center | my-5">
        <div class="col-12 col-md-8">
            <ng-container *ngIf="stellenangebot">
                <header class="o-hgroup">
                    <h2>Erfolgreich beworben</h2>
                </header>
                <div class="o-richtext">
                    <p>Vielen Dank für Ihr Interesse an unserem Stellenangebot. Sie erhalten innerhalb der nächsten 24 Stunden eine Eingangsbestätigung an die von Ihnen angegebene E-Mail-Adresse.</p>
                    <p>Bitte notieren Sie sich folgende Referenznummer für eventuelle Rückfragen: <b>{{ uuid }}</b></p>
                </div>
            </ng-container>
        </div>
    </section>
</main>
