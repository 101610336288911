<ul>
    <li>
        <a [href]="'https://www.xing.com/app/user?op=share;url=' + clipboardText" target="_blank">
            <fa-icon [fixedWidth]="true" [icon]="faXing" class="copyToClipboard" matTooltip="Diese Seite auf Xing teilen"></fa-icon>
        </a>
    </li>
    <li>
        <a [href]="'https://www.linkedin.com/shareArticle?mini=true&url=' + clipboardText" target="_blank">
            <fa-icon [fixedWidth]="true" [icon]="faLinkedinIn" class="copyToClipboard" matTooltip="Diese Seite auf LinkedIn teilen"></fa-icon>
        </a>
    </li>
    <li>
        <a [href]="'https://twitter.com/intent/tweet?source=webclient&url=' + clipboardText" target="_blank">
            <fa-icon [fixedWidth]="true" [icon]="faTwitter" class="copyToClipboard" matTooltip="Diese Seite auf Twitter teilen"></fa-icon>
        </a>
    </li>
    <li>
        <a [href]="'http://www.facebook.com/sharer.php?u=' + clipboardText" target="_blank">
            <fa-icon [fixedWidth]="true" [icon]="faFacebookF" class="copyToClipboard" matTooltip="Diese Seite auf Facebook teilen"></fa-icon>
        </a>
    </li>
    <li>
        <fa-icon [fixedWidth]="true" [icon]="faLink" class="copyToClipboard" (click)="copyToClipboard(clipboardText)" [matTooltip]="[tooltipText]"></fa-icon>
    </li>
</ul>
