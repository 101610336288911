<main class="container-fluid">
    <div class="c-page-header | row">
        <div class="page-header-image page-header-image-stellenangebote"></div>
        <div class="c-page-header__body">
            <h1>Ihre Bewerbung</h1>
        </div>
    </div>
    <section class="row justify-content-center | my-5">
        <div class="col-12 col-md-8">
            <ng-container *ngIf="stellenangebot">
                <h2>{{ stellenangebot.Titel }}</h2>
                <form
                  [formGroup]="bewerbenForm"
                  (ngSubmit)="onSubmit()"
                  class="o-sections-space mt-5">
                    <section>
                        <header>
                            <h3 class="font-weight-normal">Allgemeine Angaben</h3>
                        </header>

                        <div class="row">
                            <mat-form-field class="col-12 col-sm-6 col-xl-3">
                                <mat-label>Anrede *</mat-label>
                                <mat-select
                                  [ngModel]
                                  formControlName="Anrede"
                                  autofocus>
                                    <mat-option value="keine Angabe"></mat-option>
                                    <mat-option value="Frau">Frau</mat-option>
                                    <mat-option value="Herr">Herr</mat-option>
                                </mat-select>
                                <mat-error>Anrede erforderlich</mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12 col-sm-6 col-xl-3">
                                <mat-label>Titel</mat-label>
                                <input
                                  matInput
                                  id="titel"
                                  formControlName="Titel"
                                  maxlength="255"
                                  type="text">
                                <mat-error *ngIf="bewerbenForm.get('Titel').errors?.maxlength">Titel zu lang</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-12 col-sm-4">
                                <mat-label>Vorname</mat-label>
                                <input
                                  matInput
                                  id="vorname"
                                  formControlName="Vorname"
                                  maxlength="255"
                                  required
                                  type="text">
                                <mat-error *ngIf="bewerbenForm.get('Vorname').errors?.required">Vorname erforderlich
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Vorname').errors?.maxlength">Vorname zu lang
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12 col-sm-4">
                                <mat-label>Name</mat-label>
                                <input
                                  matInput
                                  formControlName="Name"
                                  maxlength="255"
                                  required
                                  type="text">
                                <mat-error *ngIf="bewerbenForm.get('Name').errors?.required">Name erforderlich
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Name').errors?.maxlength">Name zu lang</mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12 col-sm-4">
                                <mat-label>Geburtsdatum</mat-label>
                                <input
                                  matInput
                                  formControlName="Geburtsdatum"
                                  [matDatepicker]="pickerGeburtsdatum"
                                  maxlength="10"
                                  [max]="birthdayMaxDate"
                                  [min]="birthdayMinDate"
                                  placeholder="TT.MM.JJJJ">
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="pickerGeburtsdatum"></mat-datepicker-toggle>
                                <mat-datepicker #pickerGeburtsdatum></mat-datepicker>
                                <mat-error *ngIf="bewerbenForm.get('Geburtsdatum').errors?.maxlength">Geburtsdatum zu
                                    lang
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Geburtsdatum').invalid && !bewerbenForm.get('Geburtsdatum').errors?.matDatepickerMin && !bewerbenForm.get('Geburtsdatum').errors?.matDatepickerMax">
                                    Ungültiges Datumsformat
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Geburtsdatum').errors?.matDatepickerMin">
                                    Min. {{birthdayMinDate | date}}</mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Geburtsdatum').errors?.matDatepickerMax">Zukunft
                                    nicht möglich
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </section>

                    <section>
                        <header>
                            <h3 class="font-weight-normal">Kontaktdetails</h3>
                        </header>

                        <div class="row">
                            <mat-form-field class="col-12 col-sm-9">
                                <mat-label>Straße</mat-label>
                                <input
                                  matInput
                                  formControlName="Strasse"
                                  maxlength="255"
                                  required
                                  type="text">
                                <mat-error *ngIf="bewerbenForm.get('Strasse').errors?.required">Straße erforderlich
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Strasse').errors?.maxlength">Straße zu lang
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12 col-sm-3">
                                <mat-label>Nr.</mat-label>
                                <input
                                  matInput
                                  formControlName="Hausnummer"
                                  maxlength="10"
                                  required
                                  type="text">
                                <mat-error *ngIf="bewerbenForm.get('Hausnummer').errors?.required">Nr. erforderlich
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Hausnummer').errors?.maxlength">Nr. zu lang
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-12 col-sm-4 col-xl-3">
                                <mat-label>PLZ</mat-label>
                                <input
                                  matInput
                                  formControlName="PLZ"
                                  maxlength="10"
                                  required
                                  type="text">
                                <mat-error *ngIf="bewerbenForm.get('PLZ').errors?.required">PLZ erforderlich</mat-error>
                                <mat-error *ngIf="bewerbenForm.get('PLZ').errors?.maxlength">PLZ zu lang</mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12 col-sm-8 col-xl-9">
                                <mat-label>Ort</mat-label>
                                <input
                                  matInput
                                  formControlName="Ort"
                                  maxlength="255"
                                  required
                                  type="text">
                                <mat-error *ngIf="bewerbenForm.get('Ort').errors?.required">Ort erforderlich</mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Ort').errors?.maxlength">Ort zu lang</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-12 col-sm-6">
                                <mat-label>E-Mail</mat-label>
                                <input
                                  matInput
                                  formControlName="EMail"
                                  maxlength="255"
                                  required
                                  type="email">
                                <mat-error *ngIf="bewerbenForm.get('EMail').errors?.required">E-Mail-Adresse
                                    erforderlich
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('EMail').errors?.maxlength">E-Mail-Adresse zu lang
                                </mat-error>
                                <mat-error *ngIf="!bewerbenForm.get('EMail').errors?.maxlength && bewerbenForm.get('EMail').errors?.email">
                                    E-Mail-Adresse ungültig
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12 col-sm-6">
                                <mat-label>Telefon</mat-label>
                                <input
                                  matInput
                                  formControlName="Telefon"
                                  maxlength="255"
                                  required
                                  type="tel">
                                <mat-error *ngIf="bewerbenForm.get('Telefon').errors?.required">Telefonnummer
                                    erforderlich
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Telefon').errors?.maxlength">Telefonnummer zu lang
                                </mat-error>
                                <mat-error *ngIf="bewerbenForm.get('Telefon').errors?.pattern">Telefonnummer ungültig
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </section>

                    <section>
                        <header>
                            <h3 class="font-weight-normal">Gehaltsvorstellung</h3>
                        </header>
                        <div class="row">
                            <mat-form-field class="col-12 col-sm-6">
                                <mat-label>Gehaltsvorstellung</mat-label>
                                <input
                                  matInput
                                  formControlName="Gehaltsvorstellung"
                                  maxlength="255">
                                <mat-error *ngIf="bewerbenForm.get('Gehaltsvorstellung').errors?.maxlength">
                                    Gehaltsvorstellung zu lang
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </section>

                    <section>
                        <header>
                            <h3 class="font-weight-normal">Bewerbungsunterlagen</h3>
                        </header>
                        <div class="row mt-2">
                            <div class="col-12">
                                <p>Bitte laden Sie Ihre Bewerbungsunterlagen hoch (max. 5 Dateien à 10 MB, erlaubte
                                    Dateitypen: PDF, JPEG, JPG).</p>
                            </div>
                            <div class="col-12">
                                <input
                                  #fileInput1
                                  id="fileInput1"
                                  accept=".pdf,.jpg,.jpeg"
                                  (change)="handleFileSelect($event.target.files, 'fileInput1', 0)"
                                  type="file"
                                  name="file"/>
                                <fa-icon
                                  matSuffix
                                  *ngIf="fileInput1.value"
                                  class="faTimesCircle"
                                  (click)="resetFileInputValue('fileInput1', 0)"
                                  [fixedWidth]="true"
                                  [icon]="faTimesCircle"
                                  matTooltip="Datei entfernen"></fa-icon>
                                <mat-error
                                  *ngIf="dateienErrorMessageArray[0]"
                                  class="small">{{ dateienErrorMessageArray[0] }}</mat-error>
                                <pre id="fileContent1"></pre>
                            </div>
                            <div
                              *ngIf="this.dateien[0] || this.dateien[1]"
                              class="col-12">
                                <input
                                  #fileInput2
                                  id="fileInput2"
                                  accept=".pdf,.jpg,.jpeg"
                                  (change)="handleFileSelect($event.target.files, 'fileInput2', 1)"
                                  type="file"
                                  name="file"/>
                                <fa-icon
                                  matSuffix
                                  *ngIf="fileInput2.value"
                                  class="faTimesCircle"
                                  (click)="resetFileInputValue('fileInput2', 1)"
                                  [fixedWidth]="true"
                                  [icon]="faTimesCircle"
                                  matTooltip="Datei entfernen"></fa-icon>
                                <mat-error
                                  *ngIf="dateienErrorMessageArray[1]"
                                  class="small">{{ dateienErrorMessageArray[1] }}</mat-error>
                                <pre id="fileContent2"></pre>
                            </div>
                            <div
                              *ngIf="(this.dateien[0] && this.dateien[1]) || this.dateien[2]"
                              class="col-12">
                                <input
                                  #fileInput3
                                  id="fileInput3"
                                  accept=".pdf,.jpg,.jpeg"
                                  (change)="handleFileSelect($event.target.files, 'fileInput3', 2)"
                                  type="file"
                                  name="file"/>
                                <fa-icon
                                  matSuffix
                                  *ngIf="fileInput3.value"
                                  class="faTimesCircle"
                                  (click)="resetFileInputValue('fileInput3', 2)"
                                  [fixedWidth]="true"
                                  [icon]="faTimesCircle"
                                  matTooltip="Datei entfernen"></fa-icon>
                                <mat-error
                                  *ngIf="dateienErrorMessageArray[2]"
                                  class="small">{{ dateienErrorMessageArray[2] }}</mat-error>
                                <pre id="fileContent3"></pre>
                            </div>
                            <div
                              *ngIf="(this.dateien[0] && this.dateien[1] && this.dateien[2]) || this.dateien[3]"
                              class="col-12">
                                <input
                                  #fileInput4
                                  id="fileInput4"
                                  accept=".pdf,.jpg,.jpeg"
                                  (change)="handleFileSelect($event.target.files, 'fileInput4', 3)"
                                  type="file"
                                  name="file"/>
                                <fa-icon
                                  matSuffix
                                  *ngIf="fileInput4.value"
                                  class="faTimesCircle"
                                  (click)="resetFileInputValue('fileInput4', 3)"
                                  [fixedWidth]="true"
                                  [icon]="faTimesCircle"
                                  matTooltip="Datei entfernen"></fa-icon>
                                <mat-error
                                  *ngIf="dateienErrorMessageArray[3]"
                                  class="small">{{ dateienErrorMessageArray[3] }}</mat-error>
                                <pre id="fileContent4"></pre>
                            </div>
                            <div
                              *ngIf="(this.dateien[0] && this.dateien[1] && this.dateien[2] && this.dateien[3]) || this.dateien[4]"
                              class="col-12">
                                <input
                                  #fileInput5
                                  id="fileInput5"
                                  accept=".pdf,.jpg,.jpeg"
                                  (change)="handleFileSelect($event.target.files, 'fileInput5', 4)"
                                  type="file"
                                  name="file"/>
                                <fa-icon
                                  matSuffix
                                  *ngIf="fileInput5.value"
                                  class="faTimesCircle"
                                  (click)="resetFileInputValue('fileInput5', 4)"
                                  [fixedWidth]="true"
                                  [icon]="faTimesCircle"
                                  matTooltip="Datei entfernen"></fa-icon>
                                <mat-error
                                  *ngIf="dateienErrorMessageArray[4]"
                                  class="small">{{ dateienErrorMessageArray[4] }}</mat-error>
                                <pre id="fileContent5"></pre>
                            </div>
                            <div
                              *ngIf="dateienErrorMessage"
                              class="col-12">
                                <mat-error class="small">{{ dateienErrorMessage }}</mat-error>
                            </div>
                        </div>
                    </section>

                    <section>
                        <header>
                            <h3 class="font-weight-normal">Sonstige Anmerkungen</h3>
                        </header>
                        <div class="row">
                            <mat-form-field class="col-12">
                                <mat-label>Sonstige Anmerkungen</mat-label>
                                <textarea
                                  matInput
                                  #inputBemerkung
                                  formControlName="Bemerkung"
                                  [maxLength]="255"
                                  placeholder="Hier können Sie uns weitere Informationen mitteilen."
                                  rows="3"></textarea>
                                <mat-hint align="end">{{ inputBemerkung.value?.length || 0 }}/255</mat-hint>
                                <mat-error *ngIf="bewerbenForm.get('Bemerkung').errors?.maxlength">Sonstige Anmerkung zu
                                    lang
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-12 col-xl-6">
                                <mat-label>Wie sind Sie auf uns aufmerksam geworden?</mat-label>
                                <mat-select
                                  [ngModel]
                                  formControlName="Quelle">
                                    <mat-option [value]="quelleKeineAngabe">{{quelleKeineAngabe}}</mat-option>
                                    <mat-option value="Glöckle Karriereseite">Glöckle Karriereseite</mat-option>
                                    <mat-option value="Facebook">Facebook</mat-option>
                                    <mat-option value="Instagram">Instagram</mat-option>
                                    <mat-option value="LinkedIn">LinkedIn</mat-option>
                                    <mat-option value="Xing">Xing</mat-option>
                                    <mat-option value="Indeed">Indeed</mat-option>
                                    <mat-option value="Stepstone">Stepstone</mat-option>
                                    <mat-option value="Unterfrankenjobs">Unterfrankenjobs</mat-option>
                                    <mat-option value="Messe">Messe</mat-option>
                                    <mat-option value="Weiterempfehlung">Weiterempfehlung</mat-option>
                                    <mat-option value="Headhunter">Headhunter</mat-option>
                                    <mat-option value="Zeitung">Zeitung</mat-option>
                                    <mat-option value="Sonstige">Sonstige</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </section>

                    <section>
                        <div class="row mt-4">
                            <div class="col-12">
                                <mat-checkbox
                                  #inputDatenschutz
                                  formControlName="DatenschutzOk"
                                  required>Hiermit bestätige ich, dass ich die <a
                                  [routerLink]="['/datenschutz/']"
                                  rel="chapter"
                                  target="_blank">Datenschutzerklärung</a> gelesen und akzeptiert habe. *
                                </mat-checkbox>
                                <mat-error
                                  *ngIf="!validateFormControl(bewerbenForm, 'DatenschutzOk')"
                                  class="small">Bestätigung erforderlich
                                </mat-error>
                            </div>
                            <div class="col-12">
                                <mat-checkbox formControlName="ErweiterteDatenverwendung">Hiermit willige ich zur
                                    erweiterten Verwendung meiner Daten ein.
                                </mat-checkbox>
                                <div>
                                    <small>Mit der Bestätigung zur erweiterten Verwendung Ihrer Daten gestatten Sie uns
                                        die Aufbewahrung Ihrer Daten über den Bewerbungsprozess hinaus. So können wir
                                        Sie auch in Zukunft bei passenden Vakanzen berücksichtigen. Dieser Service ist
                                        für Sie selbstverständlich kostenfrei. Weiterhin können Sie die Einwilligung
                                        jederzeit widerrufen.</small>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <button
                                  class="btn btn-primary"
                                  [disabled]="formSubmitActive"
                                  type="submit">Bewerbung absenden
                                </button>
                                <mat-error
                                  *ngIf="bewerbenFormErrorMessage"
                                  class="small"
                                  style="margin-top: 12px;">{{ bewerbenFormErrorMessage }}</mat-error>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <small>* Pflichtfeld, Eingabe zum Absenden des Formulars erforderlich.</small>
                            </div>
                        </div>
                    </section>
                </form>
            </ng-container>
        </div>
    </section>
</main>
