import { Component, HostBinding, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MetaTagsService } from '../../services/metaTags/meta-tags.service';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.css'],
})
export class StartComponent implements OnInit {
    environment = environment;

    constructor(private metaTagsService: MetaTagsService) {}

    @HostBinding('class') class = 'o-sections-space';

    ngOnInit(): void {
        this.metaTagsService.setMetaTags({
            title: 'Glöckle Bewerberportal',
            description: `Bewerben Sie sich jetzt und werden Sie Teil der erfolgreichen Unternehmensgeschichte von Glöckle`,
        });
    }
}
