import { Injectable } from '@angular/core';
import { IStellenangebot } from 'src/app/interfaces/stellenangebot';

@Injectable({
    providedIn: 'root'
})
export class JoblevelService {

    constructor() { }

    getJoblevel(stellenangebote: IStellenangebot[]): string[] {
        let joblevel: string[] = [];

        stellenangebote.forEach(stellenangebot => {
            if (stellenangebot.JobLevel && stellenangebot.JobLevel.length > 0 && !joblevel.includes(stellenangebot.JobLevel))
            joblevel.push(stellenangebot.JobLevel);
        });
        joblevel.sort();

        return joblevel;
    }
}
