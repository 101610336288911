import { Component, OnInit, Input } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn, faTwitter, faXing } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-copytoclipboard',
    templateUrl: './copytoclipboard.component.html',
    styleUrls: ['./copytoclipboard.component.css']
})
export class CopytoclipboardComponent implements OnInit {
    @Input() clipboardText: string;
    @Input() tooltipText: string;
    faFacebookF = faFacebookF;
    faLink = faLink;
    faLinkedinIn = faLinkedinIn;
    faTwitter = faTwitter;
    faXing = faXing;

    constructor() { }

    ngOnInit(): void {
    }

    copyToClipboard(s: string) {
        const el = document.createElement('textarea');
        el.value = s;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
}
